import TitleBanner from "../../components/TitleBanner";
import {Helmet} from "react-helmet";
import React from "react";

function AboutUs() {
    return <>
        <Helmet>
            <title>SellOnFly: Connecting Retailers and Customers for Hyperlocal Shopping</title>
            <meta name="title"
                  content={`SellOnFly: Connecting Retailers and Customers for Hyperlocal Shopping`}/>
            <meta name="description"
                  content="Promote your brand with SellOnFly's Advertising platform. Perfect advertising medium for your brand visibility, making it easier for new customers.  Discover savings on fashion, food, electronics & more. Join us to celebrate local shopping!"/>
        </Helmet>
        <TitleBanner title="About Us"/>
        <section className="">
            <div className="container">
                <div className="content_text max1024 mx-auto pt60">
                    <p>SellOnFly is an innovative platform that is revolutionizing offline shopping in India. The
                        platform connects retailers, both large and small, with customers to create value for all
                        stakeholders in the hyperlocal retail ecosystem.</p>
                    <p>By leveraging the power of technology, SellOnFly allows customers to discover and save on
                        purchases from a diverse range of local stores, including fashion, food, electronics, grocery,
                        pharmacy, home delivery, spa, nightlife, entertainment, and more.</p>
                    <p>In addition to connecting retailers with customers, SellOnFly's multichannel platform provides
                        engagement and visibility for retailers and brands, boosting demand, and enabling them to
                        connect with relevant local customers more effectively.</p>
                    <p>Furthermore, customers who shop at local stores through SellOnFly can earn savings and
                        cashbacks, which can be used for shopping at any store. Whether it's big brands or local
                        favorites, SellOnFly rewards customers with savings, discounts, vouchers, and rewards for
                        choosing to shop locally.</p>
                    <p>At its core, SellOnFly is committed to creating a marketplace that celebrates the thrill of
                        shopping and savings for all, promoting a more local and relevant shopping experience. This is
                        the magic of local shopping.</p>
                    <h3>Our Team at SellOnFly</h3>
                    <p>Our team comprises highly motivated individuals with diverse backgrounds. Collectively, we
                        possess a wealth of experience in building and scaling high-performance teams from the ground
                        up, creating technology solutions that have a positive impact on people's lives, and providing
                        strategic and operational guidance to top-tier corporations.</p>
                    <h3>Connect with US!</h3>
                    <p>At SellOnFly, we take pride in having a team of intelligent, driven, and proactive individuals
                        across all functions. Our employees bring a diverse set of skills and experience to the table,
                        which enables them to innovate and execute with a keen eye on the future. If you share our
                        passion for driving the next wave of local commerce, we welcome you to reach out to us. We would
                        love to hear from you!</p>
                </div>
            </div>
        </section>
    </>;
}

export default AboutUs;
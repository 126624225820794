import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import ContentService from "../../services/ContentService";
import {useSelector} from "react-redux";
import GalleyType2 from "../../components/GalleyType2";
import ItemStandard from "../../components/ItemStandard";
import ItemCustom from "../../components/ItemCustom";
import GalleryType3 from "../../components/GalleryType3";
import GalleryType4 from "../../components/GalleryType4";
import {useNavigate} from "react-router";

const OfferDeals = () => {
    const navigate = useNavigate();
    const [contentService] = useState(new ContentService());
    const locationState = useSelector((state: any) => state.location);
    const commonState = useSelector((state: any) => state.common);
    const [sliders, setSliders] = useState([]);
    const sliderSettings = {
        autoplay: false,
        fade: false,
        draggable: true,
        autoplaySpeed: 3000,
        speed: 400,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false
    };
    const galleryType2Settings = {
        autoplay: false,
        autoplaySpeed: 2000,
        speed: 800,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 575,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            }]
    };
    const [sections, setSections] = useState([]);

    useEffect(() => {
        if (commonState.offerDealsContent.length > 0) {
            setSections(commonState.offerDealsContent);
        } else if (localStorage.getItem('offerDealsContent')) {
            setSections(JSON.parse(localStorage['offerDealsContent']));
        }
    }, [commonState]);


    return <>
        {sections.map((section) => <>
            {section['section']['type'] === 'Gallery Type 1' && <section className="banner_section offer_deal_banner">
                <div className="container">
                    <Slider {...sliderSettings} className='banner_slider'>
                        {(section['contents'] as any[]).map((slider) => <div className="banner_slide">
                            <a href={slider['url'] ?? '#'}><img src={slider['image']} alt="" srcSet="" /></a>
                        </div>)}
                    </Slider>
                </div>
            </section>}


            {section['section']['type'] === 'Gallery Type 2' && <section className="top_offers_sec pt80">
                <div className="container">
                    <div className="site_headline row align-items-center">
                        <div className="col-md-12 bb_time_title">
                            <h2>{section['section']['title']}</h2>
                        </div>
                    </div>
                    <div className="top_offers_grid">
                        {(section['contents'] as any[]).map((content) => <GalleyType2 content={content}/>)}
                    </div>
                    <div className="text-center bottom_viewmore mt-4">
                        <a href="javascript:void(0)" className="view_more_link" onClick={() => {
                            navigate(`/view-all/custom/content/${section['section']['_id']}`);
                        }}>View More<i className="ps-1 fa-solid fa-angle-down"></i></a>
                    </div>
                </div>
            </section>}

            {section['section']['type'] === 'Item Custom' && <section className="top_offers_sec pt80">
                <div className="container">
                    <div className="site_headline row align-items-center">
                        <div className="col-md-12 bb_time_title">
                            <h2>{section['section']['title']}</h2>
                        </div>
                    </div>
                    <div className="top_offers_grid">
                        {(section['contents'] as any[]).map((content) => <ItemCustom content={content}/>)}
                    </div>
                    <div className="text-center bottom_viewmore mt-4">
                        <a href="javascript:void(0)" className="view_more_link" onClick={() => {
                            navigate(`/view-all/custom/content/${section['section']['_id']}`);
                        }}>View More<i className="ps-1 fa-solid fa-angle-down"></i></a>
                    </div>
                </div>

            </section>}

            {section['section']['type'] === 'Gallery Type 3' && <section className="sponsered_banner_sec pt80">
                <Slider {...galleryType2Settings} className='sponsered_ban_slider side12'>
                    {(section['contents'] as any[]).map((content) => <GalleryType3 content={content}/>)}
                </Slider>
            </section>}

            {section['section']['type'] === 'Item Standard' && <section className="category_offers pt80">
                <div className="container">
                    <div className="site_headline row align-items-center">
                        <div className="col-md-12 bb_time_title">
                            <h2>{section['section']['title']}</h2>
                        </div>
                    </div>
                    <div className="deals_grid">
                        { (section['items'] as any[]).map((item) => <ItemStandard  item={item}/>)}
                    </div>
                    <div className="text-center bottom_viewmore mt-4">
                        <a href="javascript:void(0)" className="view_more_link" onClick={() => {
                            navigate(`/view-all/custom/content/${section['section']['_id']}`);
                        }}>View More<i className="ps-1 fa-solid fa-angle-down"></i></a>
                    </div>
                </div>
            </section>}

            {section['section']['type'] === 'Gallery Type 4' && <section className="top_brands_sec pt80">
                <div className="container">
                    <div className="site_headline row align-items-center">
                        <div className="col-md-12 bb_time_title">
                            <h2>{section['section']['title']}</h2>
                        </div>
                    </div>
                    <div className="brand_grid">
                        { (section['contents'] as any[]).map((content) => <GalleryType4  content={content}/>)}
                    </div>
                </div>
            </section>}
        </>)}
    </>;
}

export default OfferDeals;
import React, {useState} from "react";
import TitleBanner from "../../components/TitleBanner";
import {useSelector} from "react-redux";
import {toast} from "react-hot-toast";
import { AccountDeletionService } from './Service';
import {Helmet} from "react-helmet";

const SellerRequestAccountDeletion = () => {
  const [service] = useState(new AccountDeletionService());
  const [phoneNo, setPhoneNo] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await service.sellerRequestAccountDeletion(phoneNo);
    toast.success('We have received your request. We will contact you soon.');
  }

  return (
    <>
      <Helmet>
        <title>SellOnFly: Seller delete account request page.</title>
        <meta name="title"
              content={`SellOnFly: Seller delete account request page.`}/>
        <meta name="description"
              content="Easily request the deletion of your seller account on SellOnFly's platform. SellOnFly team respect your decision and strive to make the process seamless for you."/>
      </Helmet>
      <TitleBanner title={'Seller Account Deletion Request'}/>
      <section className="buisness_register_sec pt60 pb60">
        <div className="container">
          <form onSubmit={handleSubmit} className="buisness_register_form max1024 mx-auto">
            <div className="user_details_fields row field_block">
              <div className="site_headline pb-md-4 pb-2 col-12 max700">
                <p>Enter your phone number to request delete your account from SellOnFly. We will contact you further.</p>
              </div>
              <div className='d-flex justify-content-center'>
                <div className="col-md-6">
                                <span className="field_block_inputs position-relative"
                                      data-title="Enter your mobile number"><input type="tel" name="phone-no"
                                                                                   value={phoneNo}
                                                                                   onChange={(e) => setPhoneNo(e.target.value)} /></span>
                </div>
              </div>
              <div className="col-12 pt-2 text-center">
                <input type="submit" value="Submit" />
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default SellerRequestAccountDeletion;

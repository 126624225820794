import {useParams} from "react-router-dom";
import AllCustomContent from "./AllCustomContent";
import AllStandardOffer from "./AllStandardOffer";

const ViewAll = () => {
    const {type, filter, id} = useParams();


    return <>
        {type === 'custom' && <AllCustomContent sectionId={id ?? ''} />}
        {type === 'standard' && <AllStandardOffer filter={filter ?? ''} id={id ?? ''} />}
    </>;
}

export default ViewAll;
import SuperSaverDealDetailModal from "./modals/SuperSaverDealDetailModal";
import {useState} from "react";

const GoldDeal = ({deal, key}: {deal: any, key: any}) => {
    const [showDetails, setShowDetails] = useState(false);

    return <>
        <div className="gold_deal_col" key={key}>
            <div className="golddeals_block">
                <span className="storename">{deal.store.name}</span>
                <h4>{deal.title}</h4>
                <div className="details_link"><a href='#' onClick={() => setShowDetails(true)}>Details</a></div>
            </div>
        </div>
        <SuperSaverDealDetailModal item={deal} show={showDetails} handleClose={() => setShowDetails(false)} />
    </>;
}

export default GoldDeal;
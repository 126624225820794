import TitleBanner from "../../components/TitleBanner";
import {useEffect, useState} from "react";
import OrderService from "../../services/OrderService";
import OrderTile from "../../components/OrderTile";

const Orders = () => {
    const [orderService] = useState(new OrderService());
    const [orders, setOrders] = useState<any[]>([]);

    const getOrders = async () => {
        let page = 1;
        let _orders = [];
        while (page != 0) {
            const orders = await orderService.orders(page, 100);
            if (orders.length === 0) {
                page = 0;
            } else {
               _orders.push(...orders);
                page++;
            }
        }
        setOrders(_orders);
    }

    useEffect(() => {
        getOrders();
    }, []);

    return <>
        <TitleBanner title={'Orders'} />
        <section className="pt60 pb60">
            <div className="container">
                <div className="order_page max1140 mx-auto">
                    <div className="orders_all">
                        <table className="orders_table w-100">
                            <thead className="">
                            <tr>
                                <th>Order ID</th>
                                <th>Order Type</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>View Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                orders.map((order: any) => <OrderTile order={order} />)
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>

    </>;
}

export default Orders;
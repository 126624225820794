import Api from "./Api";
import BBEndpoints from "../core/config/Endpoints";

export default class SliderService {
    async listSlider({page, location}: {page?: string, location?: string}) {
        return await Api.get(BBEndpoints.sliders, {}, {
            ...(page && {page: page}),
            ...(location && {location: location}),
        });
    }
}
import TitleBanner from "../../components/TitleBanner";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import UserService from "./Service";
import store from "../../core/store";
import {profileUpdateSuccess} from "../../core/store/auth/reducers";

const UpdateProfile = () => {
    const [userService] = useState(new UserService());
    const userState = useSelector((state: any) => state.auth);
    const [name, setName] = useState(userState.name);
    const [email, setEmail] = useState(userState.email);
    const [gender, setGender] = useState(userState.gender);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const data = {
            name,
            email,
            gender,
        };
        const res = await userService.update(data);
        if (res) {
            store.dispatch({
                type: profileUpdateSuccess,
                payload: res,
            });
        }
    }

    useEffect(() => {
        setName(userState.name);
        setEmail(userState.email);
        setGender(userState.gender);
    }, [userState]);

    return <>
        <TitleBanner title={'My Account'} />
        <section className="my_account_row pt60 pb60">
            <div className="container">
                <div className="userprofile_details">
                    <h3 className="acc_title">Your Profile</h3>
                    <form onSubmit={handleSubmit} className="m-0 row">
                        <div className="col-md-6">
                            <div className="acc_fields">
                                <span className="field_block_inputs position-relative" data-title="Full Name"><input
                                    type="text" name="full-name" id="full-name" value={name} onChange={(e) => setName(e.target.value)} required={true} /></span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="acc_fields">
                                <span className="field_block_inputs position-relative" data-title="Email Address"><input
                                    type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required={true} /></span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="cart_check_fields w-100 mb-4 address_types gender_types">
                                <h4>Gender</h4>
                                <div className="row align-items center">
                                    <div className="add_type_block col-6">
                                        <input type="radio" name="gender" onChange={(e) => {
                                            if (e.target.checked) {
                                                setGender('Male')
                                            }
                                        }} checked={gender === 'Male'} id="male"/>
                                        <label htmlFor="male">Male</label>
                                    </div>
                                    <div className="add_type_block col-6">
                                        <input type="radio" name="gender" onChange={(e) => {
                                            if (e.target.checked) {
                                                setGender('Female')
                                            }
                                        }} checked={gender === 'Female'} id="female"/>
                                        <label htmlFor="female">Female</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-5 col-7 mx-auto">
                            <div className="update_btn">
                                <input type="submit" value="Update"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </>;
}

export default UpdateProfile;
import ItemDetailModal from "./modals/ItemDetailModal";
import {useState} from "react";
import CouponRedeemModal from "./modals/CouponRedeemModal";
import useRazorpay from "react-razorpay";
import BBConstants from "../core/config/Constants";
import OrderService from "../services/OrderService";
import {useSelector} from "react-redux";
import {toast} from "react-hot-toast";
import {Link} from "react-router-dom";
import Login from "../modules/Authentication/Login";
import DownloadAppModal from "./modals/DownloadAppModal";

const ItemStandard = ({item}: { item: any }) => {
    const [orderService] = useState(new OrderService());
    const razorpay = useRazorpay();
    const userState = useSelector((state: any) => state.auth);
    const commonState = useSelector((state: any) => state.common);
    const [showDetails, setShowDetails] = useState(false);
    const [showRedeemDetails, setShowRedeemDetails] = useState(false);
    const [showDownloadAppModal, setShowDownloadAppModal] = useState(false);

    const buyItem = async () => {
        if (!userState.token) {
            toast.error('Please login to buy deal/coupon');
            return;
        }
        const orderId = await orderService.createRazorpayOrder(item._id);
        const options = {
            'key': BBConstants.razorpayKey,
            'order_id': orderId,
            'name': 'SellOnFly',
            'description': 'Deal Purchase',
            'prefill': {
                'contact': userState.phoneNumber ?? ''
            },
            handler: async function (response: any) {
                const res = await orderService.verifyOrder(item._id, {
                    'razorpay_order_id': response.razorpay_order_id,
                    'razorpay_payment_id': response.razorpay_payment_id,
                    'razorpay_signature': response.razorpay_signature,
                });
                if (res) {
                    toast.success('Deal purchased successfully');
                } else {
                    toast.error('Something went wrong. Please try again or contact support');
                }
            },
        };
        // @ts-ignore
        const rzp1 = new razorpay(options);

        rzp1.on("payment.failed", function (response: any) {
            toast.error(response.error.description);
        });

        rzp1.open();
    }

    return <div className="deals_block">

        {item.store &&
            <div className="dealstore_name"><Link to={`/store/${item.store._id}`}>{item.store.name}</Link></div>}
        <h4>{item.title}</h4>
        <div className="deal_btm_row">
            {item.category && <div className="deal_cat"><span>{item.category.title}</span></div>}
            <div className="deals_btn">
                <div className="details_link"><a onClick={() => setShowDetails(true)}
                                                 style={{cursor: 'pointer'}}>Details</a></div>
            </div>
        </div>
        <div className="deals_btn">
            <div className="deal_btn_top">
                <button onClick={() => {
                    setShowDownloadAppModal(true);
                }} className="btn"
                        style={{backgroundColor: "#5F40A6", color: "white", width: "100%", margin: "15px 0 0 0"}}>Book
                    deal @ Rs.{commonState.configs.find((config: any) => config.attribute === 'FREE_USER_DEAL_PRICE').value}
                </button>
            </div>
        </div>
        <ItemDetailModal item={item} show={showDetails} handleClose={() => setShowDetails(false)}/>
        <CouponRedeemModal item={item} show={showRedeemDetails} handleClose={() => setShowRedeemDetails(false)}/>
        <DownloadAppModal handleClose={() => setShowDownloadAppModal(!showDownloadAppModal)} show={showDownloadAppModal}/>
    </div>;
}

export default ItemStandard;

import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const GalleyType2 = ({content}: {content: any}) => {
    const [redirectUrl, setRedirectUrl] = useState('');

    useEffect(() => {
        if (content['type'] === 'Deal') {
            setRedirectUrl(`/deal/${content['item']}`)
        } else  if (content['type'] === 'Coupon') {
            setRedirectUrl(`/coupon/${content['item']}`)
        } else if (content['type'] === 'Store') {
            setRedirectUrl(`/store/${content['item']}`)
        }
    }, [content]);

    return <div className="top_offers">
        <Link to={redirectUrl}><img src={content.image} alt="" srcSet=""/></Link>
    </div>;
}

export default GalleyType2;
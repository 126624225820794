import {Modal} from "react-bootstrap";
import {useState} from "react";
import UserService from "./Service";
import {toast} from "react-hot-toast";
import store from "../../core/store";
import {loginSuccess} from "../../core/store/auth/reducers";

function Login({show, handleClose}: {show: boolean, handleClose: VoidFunction}) {
    const [userService] = useState(new UserService());
    const [otpSend, setOtpSend] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [otp, setOtp] = useState(0);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (otpSend) {
            if (otp !== 0) {
                const res = await userService.login(phoneNumber, verificationId ?? '', otp);
                if (res) {
                    store.dispatch({
                        type: loginSuccess,
                        payload: res
                    });
                    setOtpSend(false);
                    handleClose();
                }
            } else {
                toast.error('Please fill all the fields to continue');
                return;
            }
        } else {
            if (phoneNumber.length === 0) {
                toast.error('Please fill all the fields to continue');
                return;
            }
            const verificationId = await userService.sendVerificationOTP(phoneNumber);
            setVerificationId(verificationId);
            setOtpSend(true);
        }
    }

    return <Modal className="log_reg modal m-0 p-0" id="login_popup" show={show} centered>
        <Modal.Body className="modal-dialog modal-dialog-centered m-0 p-0">
            <div className="modal-content modal_content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
                <div className="modal-body">
                    <div className="pop_head">
                        <img src="/assets/images/bb-logo.png" alt="" srcSet="" />
                    </div>
                    <div className="pop_fields">
                        <form onSubmit={handleSubmit} className="login_form">
                            <div className="user_details_fields field_block first_block">
                                <div className="field_title_des">
                                    <h3>Login</h3>
                                    {!otpSend ? <p>Please enter your details here.</p> : <div className="field_title_des">
                                        <p>We have sent you a 6 digit OTP on your registered <span>mobile number</span> .
                                        </p>
                                    </div>}
                                </div>
                                <span className="field_block_inputs position-relative"
                                      data-title="Phone Number"><input type="number" name="username" onChange={(e) => setPhoneNumber(e.target.value)} required={true}/></span>
                                {otpSend &&
                                    <span className="field_block_inputs position-relative" data-title="Enter OTP">
                                    <input type="number" name="forgot-otp" onChange={(e) => setOtp(parseInt(e.target.value))} required={true}/>
                                </span>}
                                <input type="submit" value={otpSend ? 'Login' : 'Send OTP'}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>;
}

export default Login;

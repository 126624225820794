import React, {useEffect, useState} from "react";
import ContentService from "../services/ContentService";
import {useSelector} from "react-redux";
import Slider from "react-slick";
import GalleyType2 from "../components/GalleyType2";
import ItemStandard from "../components/ItemStandard";
import CategoryType1 from "../components/CategoryType1";
import ItemCustom from "../components/ItemCustom";
import Script from "react-load-script";
import GalleryType3 from "../components/GalleryType3";
import GalleryType4 from "../components/GalleryType4";
import {useNavigate} from "react-router";
import ListService from "../services/ListService";
import {Link, useParams} from "react-router-dom";
import store from "../core/store";
import {locationUpdated} from "../core/store/location/reducers";
import {Helmet} from "react-helmet";

const Home = () => {
    const {location} = useParams();
    const navigate = useNavigate();
    const [metaLocation, setMetaLocation] = useState('Rajasthan');
    const [listService] = useState(new ListService());
    const commonState = useSelector((state: any) => state.common);
    const locationState = useSelector((state: any) => state.location);
    const sliderSettings = {
        autoplay: true,
        fade: false,
        draggable: true,
        autoplaySpeed: 3000,
        speed: 800,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false
    };
    const galleryType2Settings = {
        autoplay: true,
        autoplaySpeed: 0,
        speed: 6000,
        infinite: true,
        slidesToShow: 4,
        swipeToSlide: true,
        slidesToScroll: 1,
        pauseOnHover: false,
        cssEase: 'linear',
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 575,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            }]
    };
    const [sections, setSections] = useState([]);
    const [stores, setStores] = useState([]);

    useEffect(() => {
        if (commonState.homeContent.length > 0) {
            setSections(commonState.homeContent);
        } else if (localStorage.getItem('homeContent')) {
            setSections(JSON.parse(localStorage['homeContent']));
        }
    }, [commonState, locationState]);

    const getStores = async () => {
        if (commonState.locations.length > 0 && locationState.city) {
            const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
            const allStores = await listService.stores(location._id, {limit: 20, coordinates: locationState.coordinates});
            setStores(allStores);
        }
    }

    useEffect(() => {
       getStores();
    }, [commonState, locationState]);


    useEffect(() => {
        if (location) {
            // If location is India, then set the location to Rajasthan
            if (location.toLowerCase() === 'india') {
                store.dispatch({
                    type: locationUpdated,
                    payload: {
                        state: 'Rajasthan',
                    }
                });
                setMetaLocation('rajasthan');
            }
            if (location.toLowerCase().split('-').length > 1) {
                const locationParts = location.split('-');
                // Capitalize the first letter of the city and state
                locationParts[0] = locationParts[0].charAt(0).toUpperCase() + locationParts[0].slice(1);
                locationParts[1] = locationParts[1].charAt(0).toUpperCase() + locationParts[1].slice(1);
                store.dispatch({
                    type: locationUpdated,
                    payload: {
                        city: locationParts[0],
                        state: locationParts[1]
                    }
                });
                setMetaLocation(`${locationParts[0].toLowerCase()}, ${locationParts[1].toLowerCase()}`);
            } else if (location.split('-').length === 1) {
                const locationParts = location.split('-');
                const city = locationParts[0].charAt(0).toUpperCase() + locationParts[0].slice(1);
                store.dispatch({
                    type: locationUpdated,
                    payload: {
                        city: city,
                    }
                });
                setMetaLocation(location.toLowerCase());
            }
        }
    }, [location]);

    return <>
        <Helmet>
            <title>Checkout Best local Retailers, SME's in {metaLocation}|SellOnFly</title>
            <meta name="title"
                  content={`Checkout Best local Retailers, SME's in ${metaLocation}|SellOnFly`}/>
            <meta name="description"
                  content="SellOnFly Your One-Stop Shop unique platform connecting local retailers & customers across India. Discover savings on diverse purchases from local stores. Join us to engage with local brands & earn rewards ."/>
        </Helmet>
        {sections.map((section) => <>
            {section['section']['type'] === 'Gallery Type 1' && <section className="banner_section">
                <div className="container">
                    <Slider {...sliderSettings} className='banner_slider'>
                        {(section['contents'] as any[]).map((slider) => <div className="banner_slide">
                            <a href={slider['url'] ?? '#'}><img src={slider['image']} alt="" srcSet=""/></a>
                        </div>)}
                    </Slider>
                </div>
            </section>}

            {section['section']['type'] === 'Category Type 1' && <section className="category_sec">
                <div className="container">
                    <div className="home_categories">
                        {(section['items'] as any[]).map((item) => <CategoryType1 item={item}/>)}
                    </div>
                </div>
            </section>}


            {section['section']['type'] === 'Gallery Type 3' && <section className="sponsered_banner_sec pt80">
                <Slider {...galleryType2Settings} className='sponsered_ban_slider side12'>
                    {(section['contents'] as any[]).map((content) => <GalleryType3 content={content}/>)}
                </Slider>
            </section>}

        </>)}

        <section className="top_brands_sec pt80">
            <div className="container">
                <div className="site_headline row align-items-center">
                    <div className="col-md-12 bb_time_title">
                        <h2>Top Stores</h2>
                    </div>
                </div>
                <div className="brand_grid">
                    { stores.map((content) => <div className="brands_block">
                        <Link to={`/store/${content['_id']}`}>
                            <img src={content['logo'] != null ?  content['logo'] : '/assets/images/logo.png'} alt="" srcSet="" style={{width: '100px', height:'100px', objectFit: 'cover'}}/>
                            <span><small>{content['name']}</small></span>
                        </Link>
                    </div>)}
                </div>
            </div>
        </section>
    </>;
}

export default Home;

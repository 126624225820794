import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import BlogService from "./Service";
import {format} from "date-fns";

const Article = () => {
    const {id} = useParams();
    const [blogService] = useState(new BlogService());
    const [article, setArticle] = useState<any|null>(null);

    const getArticleDetails = async () => {
        const details = await blogService.details(id ?? '');
        console.log(details);
        setArticle(details);
    }

    useEffect(() => {
        getArticleDetails();
    }, [])

    // @ts-ignore
    return <>
        {article && <>
            <section className="banner_section inner_bg">
                <div className="container">
                    <h1 className="page_title text-center m-0 mx-auto text-white">{article.title}</h1>
                    <div className="blog_date text-center text-white mt-2">{format(new Date(article.createdAt), "dd MMM yyyy")}</div>
                </div>
            </section>

            <section className="pt60 pb60">
                <div className="container">
                    <div className="single_blog max1024 mx-auto">
                        {article.image &&  <div className="single_blog_images">
                            <img src={article.image} alt="" srcSet="" />
                        </div>}
                        <div className="single_blog_content">
                            <div dangerouslySetInnerHTML={{__html: article.content}} />
                        </div>
                    </div>
                </div>
            </section>
        </>}
    </>;
}

export default Article;

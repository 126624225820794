import Api from "../../services/Api";
import BBEndpoints from "../../core/config/Endpoints";

export default class UserService {
    async login(phoneNumber: string, verificationId: string, otp: number) {
        const response = await Api.post(BBEndpoints.login, {
            phoneNumber: phoneNumber,
            verificationId: verificationId,
            otp: otp
        }, {
            "Content-Type": "application/json"
        });

        return response ?? null;
    }

    async sendVerificationOTP(phoneNumber: string) {
        const response = await Api.post(BBEndpoints.sendVerificationOTP, {
            phoneNumber: phoneNumber
        }, {
            "Content-Type": "application/json"
        });

        return response ?? null;
    }

    async refreshToken() {
        const token = localStorage.getItem("token");
        const response = await Api.get(BBEndpoints.refreshToken, {
            "Content-Type": "application/json",
            "Authorization": token || '',
        });

        return response ?? null;
    }

    async update({ name, email, gender } : {name: string, email: string, gender: string}) {
        const token = localStorage.getItem("token");
        const data = {
            name: name,
            email: email,
            gender: gender,
        };

        const response = await Api.patch(BBEndpoints.updateProfile, data, {
            "Content-Type": "application/json",
            "Authorization": token || '',
        });

        return response ?? null;
    }

    async deleteAccount() {
        const token = localStorage.getItem("token");
       if (token !== null) {
           const response = await Api.get(BBEndpoints.deleteAccount, {
               "Content-Type": "application/json",
               "Authorization": token || '',
           });
           return response ?? null;
       }
    }
}

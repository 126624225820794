import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import Base from './modules/Base';
import Footer from './components/Footer';
import AboutUs from './modules/Other/AboutUs';
import TermsOfUse from './modules/Other/TermsOfUse';
import HowItWorks from './modules/Other/HowItWorks';
import Faq from './modules/Other/Faq';
import ContactUs from './modules/Contact/ContactUs';
import {Toaster} from 'react-hot-toast';
import AllBlogs from './modules/Blog/AllBlogs';
import CommonService from './services/CommonService';
import LocationPicker from './components/LocationPicker';
import OfferDeals from './modules/OfferDeals/OfferDeals';
import Home from './modules/Home';
import {useSelector} from 'react-redux';
import ContentService from './services/ContentService';
import store from './core/store';
import {categoryContentLoaded, homeContentLoaded, offerDealsContentLoaded} from './core/store/common/reducers';
import UserService from './modules/Authentication/Service';
import {refreshTokenSuccess} from './core/store/auth/reducers';
import UpdateProfile from './modules/Authentication/UpdateProfile';
import Category from './modules/Category/Category';
import SubCategory from "./modules/Category/SubCategory";
import {loadCurrentLocation} from "./core/store/location/reducers";
import Store from "./modules/Store/Store";
import Article from "./modules/Blog/Article";
import GoldMembership from "./modules/GoldMembership/GoldMembership";
import Orders from "./modules/Order/Orders";
import ViewAll from "./modules/ViewAll/ViewAll";
import Coupon from "./modules/Store/Coupon";
import Deal from "./modules/Store/Deal";
import SuperSaverPartners from "./components/modals/SuperSaverPartners";
import Partner from "./modules/Partner/Partner";
import RequestAccountDeletion from "./modules/AccountDeletion/RequestAccountDeletion";
import SellerRequestAccountDeletion from './modules/AccountDeletion/SellerRequestAccountDeletion';
import Sitemap from './Sitemap';
import AllStores from "./modules/Store/AllStores";

function App() {
	const [contentService] = useState(new ContentService());
	const [commonService] = useState(new CommonService());
	const commonState = useSelector((state: any) => state.common);
	const locationState = useSelector((state: any) => state.location);
	const [userService] = useState(new UserService());
	const [initialized, setInitialized] = useState(false);
	const userState = useSelector((state: any) => state.auth);

	const getPageContent = async () => {
		const location = commonState.locations.find((loc: any) => loc.title.includes(locationState.city));
		console.log('LOCATION');
		console.log(location);
		if (location) {
			const offerDeals = await contentService.getContent('Offer & Deals', location._id);
			const home = await contentService.getContent('Home', location._id);
			const category = await contentService.getContent('Category', location._id);
			store.dispatch({
				type: offerDealsContentLoaded,
				payload: offerDeals,
			});
			store.dispatch({
				type: homeContentLoaded,
				payload: home,
			});
			store.dispatch({
				type: categoryContentLoaded,
				payload: category,
			});
		}
	};

	useEffect(() => {
		if (userState.token !== null && !initialized) {
			setInitialized(true);
		}
	}, [userState.token]);

	useEffect(() => {
		const token = localStorage.getItem('token');
		console.log(token);
		if (token) {
			userService.refreshToken().then(user => {
				if (user !== null) {
					store.dispatch({type: refreshTokenSuccess, payload: user});
				}
			});
		}
	}, []);

	useEffect(() => {
		getPageContent();
	}, [commonState.locations, locationState.city]);

	useEffect(() => {
		commonService.getCategories();
		commonService.getLocations();
		commonService.getConfigs();
		store.dispatch({
			type: loadCurrentLocation,
		})
	}, []);


	return (
		<>
			<Header/>
			<Routes>

				{/* Base Route */}
				<Route path='/' element={
					<Base children={<Home />}/>
				}/>

				{/* User Routes */}
				<Route path='/update-profile' element={
					<Base children={<UpdateProfile />}/>
				}/>

				{/* Offer and Deals */}
				<Route path='/offer-deals' element={
					<Base children={<OfferDeals/>}/>
				}/>

				{/* Request Account Deletion */}
				<Route path='/delete-account' element={
					<Base children={<RequestAccountDeletion/>}/>
				}/>
				<Route path='/seller-delete-account' element={
					<Base children={<SellerRequestAccountDeletion/>}/>
				}/>

				<Route path='/about-us' element={
					<Base children={<AboutUs/>}/>
				}/>
				<Route path='/terms-of-use' element={
					<Base children={<TermsOfUse />}/>
				}/>
				<Route path='/how-it-works' element={
					<Base children={<HowItWorks />}/>
				}/>
				<Route path='/faq' element={
					<Base children={<Faq />}/>
				}/>
				<Route path='/contact-us' element={
					<Base children={<ContactUs />}/>
				}/>
				<Route path='/partner' element={
					<Base children={<Partner />}/>
				}/>
				<Route path='/blogs' element={
					<Base children={<AllBlogs />}/>
				}/>
				<Route path='/blogs/:id' element={
					<Base children={<Article />}/>
				}/>
				<Route path='/category/:slug' element={
					<Base children={<Category />}/>
				}/>
				<Route path='/category/:mainSlug/:slug' element={
					<Base children={<SubCategory />}/>
				}/>
				<Route path='/store/:id' element={
					<Base children={<Store />}/>
				}/>
				<Route path='/store/:id/outlet/:outlet' element={
					<Base children={<Store />}/>
				}/>
				<Route path='/gold-membership' element={
					<Base children={<GoldMembership />}/>
				}/>
				<Route path='/orders' element={
					<Base children={<Orders />}/>
				}/>
				<Route path='/view-all/:type/:filter/:id' element={
					<Base children={<ViewAll />}/>
				}/>
				<Route path='/deal/:id' element={
					<Base children={<Deal />}/>
				}/>
				<Route path='/coupon/:id' element={
					<Base children={<Coupon />}/>
				}/>
				<Route path='/vouchers' element={
					<Base children={<SuperSaverPartners />}/>
				}/>
				<Route path='/sitemap' element={
					<Sitemap />
				}/>
				<Route path='/:location' element={
					<Base children={<Home />}/>
				}/>
				<Route path='/stores' element={
					<Base children={<AllStores />}/>
				}/>
			</Routes>
			<Footer/>
			<LocationPicker />
			<Toaster />
		</>
	);
}

export default App;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {useEffect, useState} from "react";
import SliderService from "../../services/Slider";
import {useSelector} from "react-redux";
import BlogService from "./Service";
import {Link} from "react-router-dom";
import { format } from 'date-fns'


function AllBlogs() {
    const categories = useSelector((state: any) => state.common.categories.filter((cat: any) => !cat.parent));
    const [sliderService] = useState(new SliderService());
    const [blogService] = useState(new BlogService());
    const [currentCategory, setCurrentCategory] = useState({
        _id: 'All',
        title: 'All'
    });
    const [articles, setArticles] = useState<any>([]);

    const [sliders, setSliders] = useState([]);
    const sliderSettings = {
        autoplay: false,
        fade: false,
        draggable: true,
        autoplaySpeed: 3000,
        speed: 400,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false
    };

    const getSliders = async() => {
        const sliders = await sliderService.listSlider({page: 'Blogs'});
        setSliders(sliders);
    }

    const getArticles = async() => {
        let _page = 1;
        let articles = [];
        while (_page !==0 ) {
            const _articles = await blogService.getArticles({search: '', filter: 'All', category: currentCategory._id !== 'All' ? currentCategory._id : undefined, page: _page});
            if (_articles.length === 0) {
                _page = 0
            } else {
                _page++;
                articles.push(..._articles);
            }
        }
        setArticles(articles);
    }

    useEffect(() => {
        getSliders();
    }, []);

    useEffect(() => {
        getArticles();
    },[currentCategory])



    return <>
        <section className="blog_header">
            <Slider {...sliderSettings} className='blog_slider'>
                {sliders.map((slider) => <div className="blog_slide">
                    <a href={slider['url'] ?? '#'}><img src={slider['image']} alt="" srcSet="" /></a>
                </div>)}
            </Slider>
        </section>

        <section className="pt60 pb60">
            <div className="container">
                <div className="all_blogs_tab">
                    <ul className="nav nav-tabs blog_tabs px-0" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation" onClick={async () => {
                            setCurrentCategory({
                                _id: 'All',
                                title: 'All'
                            });
                        }}>
                            <a className={`nav-link ${currentCategory._id === 'All' ? 'active' : ''}`} id="all-tab" data-bs-toggle="tab" href="#all" role="tab"
                               aria-controls="all" aria-selected="true">all</a>
                        </li>
                        {categories.map((cat: any) => <li className="nav-item" role="presentation" onClick={async () => {
                            setCurrentCategory(cat);
                        }}>
                            <a className={`nav-link ${currentCategory === cat._id ? 'active' : ''}`} id="all-tab" data-bs-toggle="tab" href="javascript:void(0);" role="tab"
                               aria-controls="all" aria-selected="true">{cat.title}</a>
                        </li>)}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade active show" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div className="blog_list">
                                <div className="blog_grid_row">
                                    {articles.map((article: any) => <div className="blog_block">
                                        <div className="blog_image"><Link to={`${location.pathname}/${article._id}`}><img src={article.image}
                                                                                                    alt="" srcSet="" /></Link></div>
                                        <div className="blog_content">
                                            <div className="blog_date">{format(new Date(article.createdAt), "dd MMM yyyy")}</div>
                                            <h3 className="blog_title"><Link to={`/blogs/${article._id}`}>{article.title}</Link></h3>
                                            <p className="blog_short_description">{article.description}</p>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>;
}

export default AllBlogs;

import axios from 'axios';
import { toast } from 'react-hot-toast';
import BBConstants from "../core/config/Constants";

export default class Api {

    static async post(endpoint: string, data: any, headers: any = {}, params: any = {}): Promise<any> {
        const url = `${process.env.REACT_APP_HTTPS}://${BBConstants.apiDomain}/apis/v1/core${endpoint}`;
        console.log(url);
        const body = JSON.stringify(data);
        console.log(body);
        try {
            const response = await axios.post(url, body, { headers, params });
            console.log(response.data);
            return response.data;
        } catch (error: any) {
            const responseBody = error.response.data;
            toast.error(responseBody.message);
            return null;
        }
    }

    static async get(endpoint: string, headers: any = {}, params: any = {}): Promise<any> {
        const url = `${process.env.REACT_APP_HTTPS}://${BBConstants.apiDomain}/apis/v1/core${endpoint}`;
        console.log(url);
        try {
            const response = await axios.get(url, { headers, params });
            console.log(response.data);
            return response.data;
        } catch (error: any) {
            const responseBody = error.response.data;
            // toast.error(responseBody.message);
            return null;
        }
    }

    static async delete(endpoint: string, headers: any = {}, params: any = {}): Promise<any> {
        const url = `${process.env.REACT_APP_HTTPS}://${BBConstants.apiDomain}/apis/v1/core${endpoint}`;
        console.log(url);
        try {
            const response = await axios.delete(url, { headers, params });
            console.log(response.data);
            return response.data;
        } catch (error: any) {
            const responseBody = error.response.data;
            toast.error(responseBody.message);
            return null;
        }
    }

    static async patch(endpoint: string, data: any, headers: any = {}): Promise<any> {
        const url = `${process.env.REACT_APP_HTTPS}://${BBConstants.apiDomain}/apis/v1/core${endpoint}`;
        console.log(url);
        const body = JSON.stringify(data);
        try {
            const response = await axios.patch(url, body, { headers });
            console.log(response.data);
            return response.data;
        } catch (error: any) {
            const responseBody = error.response.data;
            toast.error(responseBody.message);
            return null;
        }
    }

    static async upload(endpoint: string, headers: any = {}, files: any[]): Promise<any> {
        const url = `${process.env.REACT_APP_HTTPS}://${BBConstants.apiDomain}/apis/v1/core${endpoint}`;
        console.log(url);
        const formData = new FormData();
        for (let x = 0; x < files.length; x++) {
            formData.append('files', files[x]);
        }
        try {
            const response = await axios.post(url, formData, { headers });
            console.log(response.data);
            return response.data;
        } catch (error: any) {
            const responseBody = error.response.data;
            toast.error(responseBody.message);
            return null;
        }
    }
}

export default class BBEndpoints {
    static contact = '/contact';
    static sellerRequestAccountDeletion = '/seller-request-account-deletion';
    static blogs = '/user/blog';
    static sliders = '/user/slider/all';
    static categories = '/categories';
    static configs = '/configs';
    static locations = '/locations';
    static customSections = '/user/custom-section/all';
    static login = '/user/auth/login';
    static sendVerificationOTP = '/user/auth/verify';
    static refreshToken = '/user/auth/refresh-token';
    static deleteAccount = '/user/auth/delete-account';
    static updateProfile = '/user/auth/update-profile';
    static deal = '/user/deal';
    static voucher = '/user/voucher';
    static stores = '/user/item/stores';
    static deals = '/user/item/deals';
    static vouchers = '/user/item/vouchers';
    static coupons = '/user/item/coupons';
    static store = '/user/store';
    static isGoldMember = '/user/gold-member/is-member';
    static goldMember = '/user/gold-member';
    static createGoldMemberOrder = '/user/gold-member/create-order';
    static verifyGoldMemberOrder = '/user/gold-member/verify-order';
    static coupon = '/user/coupon';
    static order = '/user/order';
    static addSearchHistory = '/user/search-history/add';
    static addUserSearchHistory = '/user/search-history/user/add';
    static trendingSearches = '/user/search-history/trending';
    static recentSearches = '/user/search-history/recent';
    static bbPay  = '/user/bb-pay';
    static partnerRequest  = '/user/partner-request/request';
}

import {Modal} from "react-bootstrap";
import moment from "moment/moment";

const SuperSaverDealDetailModal = ({item, show, handleClose}: { item: any, show: boolean, handleClose: VoidFunction }) => {
    return <Modal className="modal fade" id="store_tandc_modal p-0 m-0" role="dialog"
                  show={show} size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
        <Modal.Body className="tandc_content bg-white w-100" role="document">
            {/* <div className="modal-content modal_content p-0 m-0">
                <div className="modal-body "> */}
                    <div className="simple_content">
                        <h5>Details</h5>
                        <p>{item.description ? item.description : item.shortDescription}</p>
                    </div>
                    <div className="simple_content deals_validity">
                        <h5>Validity</h5>
                        <p><i className="fa-solid fa-calendar-days pe-2"></i>Redeemable for <strong>all days.</strong>
                        </p>
                        {item.expiryDate &&
                            <p><i className="fa-solid fa-clock pe-2"></i><strong>Expires</strong> on <strong>
                                {moment(item.expiryDate).format('DD MMM YYYY')}
                            </strong></p>}
                    </div>
                    <div className="simple_content redeem_process">
                        <h5>How To Redeem Bohni Batta Membership Deals At Stores</h5>
                        <ol>
                            <li>Check out all the latest Super Saver Membership Deals on the SellOnFly app at Bohni
                                Batta - Super Saver Membership.
                            </li>
                            <li>Visit the registered store and get the discount offers available on that store.
                            </li>
                            <li>It is advised to call the store before visiting.</li>
                            <li>We are currently in the process of placing stickers indicating super saver membership
                                deals outside stores that are registered on the Bohni Batta platform.
                            </li>
                        </ol>
                    </div>
                    <div className="simple_content redeem_process">
                        <h5>Membership Card Terms and Conditions</h5>
                        <ul>
                            <li>The Card is available to individuals who meet the eligibility criteria set by the
                                SellOnFly.
                            </li>
                            <li>SellOnFly reserves the right to modify or withdraw any benefits or privileges
                                associated with the Card at any time, with or without notice.
                            </li>
                            <li>SellOnFly reserves the right to terminate or suspend the Cardholder's membership at its
                                discretion, without liability, for any reason including, but not limited to, violation
                                of these terms and conditions.
                            </li>
                            <li>The Card is non-transferable, and its use is limited to the Cardholder only.</li>
                            <li>SellOnFly shall not be held liable for any damages, losses, or expenses incurred by the
                                Cardholder arising from the use or inability to use the Card or any associated benefits
                                or privileges.
                            </li>
                            <li>By purchasing and using the Super Saver Membership Card, the Cardholder acknowledges and
                                agrees to be bound by these terms and conditions.
                            </li>
                        </ul>
                    </div>
                {/* </div>
            </div> */}
        </Modal.Body>
    </Modal>;
}

export default SuperSaverDealDetailModal;
import Api from "../../services/Api";
import BBEndpoints from "../../core/config/Endpoints";

export default class BlogService {

    async getArticles({category, search, filter, page, limit} : {category?: string, search?: string, filter?: string, page?: number | undefined, limit?: number | undefined}) {

        return await Api.get(`${BBEndpoints.blogs}/all/${filter}`, {}, {
            ...(category && {category: category}),
            search: search,
            "page": `${page ?? 1}`,
            "limit": `${limit ?? 20}`
        });
    }

    async details(id: string) {
        return await Api.get(`${BBEndpoints.blogs}/${id}`);
    }
}

import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import GalleyType2 from "../../components/GalleyType2";
import ItemCustom from "../../components/ItemCustom";
import ItemStandard from "../../components/ItemStandard";

const AllCustomContent = ({sectionId}: {sectionId: string}) => {
    const commonState = useSelector((state: any) => state.common);
    const [section, setSection] = useState(null);

    const getItems = () => {
        let content;
        content = commonState.homeContent.find((content: any) => content.section._id === sectionId);
        console.log(content);
        if (content) {
            setSection(content);
        } else {

            content = commonState.offerDealsContent.find((content: any) => content.section._id === sectionId);
            if (content) {
                setSection(content);
            }
        }
    }

    useEffect(() => {
        getItems();
    }, [sectionId, commonState.homeContent, commonState.offerDealsContent]);

    return <>
        {section && section['section']['type'] === 'Gallery Type 2' && <section className="top_offers_sec arrow_top pt80">
            <div className="container">
                <div className="site_headline row align-items-center">
                    <div className="col-md-12 bb_time_title">
                        <h2>{section['section']['title']}</h2>
                    </div>
                </div>
                <div className="top_offers_grid">
                    {(section['contents'] as any[]).map((content) => <GalleyType2 content={content}/>)}
                </div>
            </div>
        </section>}

        {section && section['section']['type'] === 'Item Custom' && <section className="top_offers_sec arrow_top pt80">
            <div className="container">
                <div className="site_headline row align-items-center">
                    <div className="col-md-12 bb_time_title">
                        <h2>{section['section']['title']}</h2>
                    </div>
                </div>
                <div className="top_offers_grid">
                    {(section['contents'] as any[]).map((content) => <ItemCustom content={content}/>)}
                </div>
            </div>

        </section>}

        {section && section['section']['type'] === 'Item Standard' && <section className="category_offers pt80 pb80">
            <div className="container">
                <div className="site_headline row align-items-center">
                    <div className="col-md-12 bb_time_title">
                        <h2>{section['section']['title']}</h2>
                    </div>
                </div>
                <div className="deals_grid">
                    { (section['items'] as any[]).map((item) => <ItemStandard  item={item}/>)}
                </div>
            </div>
        </section>}
    </>;
}

export default AllCustomContent;
import TitleBanner from "../../components/TitleBanner";
import React, {useState} from "react";
import ContactService from "./Service";
import _ from 'lodash';
import {toast} from "react-hot-toast";
import {Helmet} from "react-helmet";

function ContactUs() {
    const [contactService] = useState(new ContactService());
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (_.isEmpty(firstName) || _.isEmpty(email) || _.isEmpty(phoneNumber) || _.isEmpty(message)) {
            toast.error('Please fill all the fields');
            return;
        }
        await contactService.contact({firstName: firstName, lastName: lastName, email: email, phoneNumber: phoneNumber, message: message});
        toast.success('We received your message and will get in touch with you shortly');
        setMessage('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
    }

    return <>
        <Helmet>
            <title>SellOnFly, contact us, inquiries, feedback, assistance, support team</title>
            <meta name="title"
                  content={`SellOnFly, contact us, inquiries, feedback, assistance, support team`}/>
            <meta name="description"
                  content="Contact SellOnFly for inquiries, feedback, and assistance. Reach out to our dedicated support team for prompt assistance with any questions or concerns."/>
        </Helmet>
        <TitleBanner title="Contact Us" />
        <div className="contact_form_sec pt60 pb60">
            <div className="container">
                <form onSubmit={handleSubmit} className="buisness_register_form max1024 mx-auto">
                    <div className="contact_form row field_block max600 mx-auto">
                        <div className="site_headline pb-2 col-12 max700">
                            <h2 className="pb-1">Get In Touch</h2>
                            <p>Connect with us today to experience unparalleled <br />support and assistance.</p>
                        </div>
                        <div className="col-12 contact_details">
                            <p><a href="tel:9352836766"><i className="fa fa-mobile pe-2" aria-hidden="true"></i>+9193528 36766</a></p>
                            <p><a href="mailto:sales@sellonfly.com"><i className="fa fa-inbox pe-2" aria-hidden="true"></i>sales@sellonfly.com</a></p>
                            <p><i className="fa fa-location-pin pe-2" aria-hidden="true"></i>I Start Nest, Near Dainik Navjyoti Office, CAD Circle Road, Kota-324005</p>
                        </div>
                        <div className="col-md-6">
                            <span className="field_block_inputs position-relative" data-title="First Name"><input
                                type="text" name="first-name" onChange={(e) => {
                                    setFirstName(e.target.value);
                            }} value={firstName} required={true} /></span>
                        </div>
                        <div className="col-md-6">
                            <span className="field_block_inputs position-relative" data-title="Last Name"><input
                                type="text" name="last-name" onChange={(e) => {
                                setLastName(e.target.value);
                            }} value={lastName} /></span>
                        </div>
                        <div className="col-md-6">
                            <span className="field_block_inputs position-relative" data-title="Email Address"><input
                                type="email" name="email" onChange={(e) => {
                                setEmail(e.target.value);
                            }}  required={true} value={email} /></span>
                        </div>
                        <div className="col-md-6">
                            <span className="field_block_inputs position-relative" data-title="Phone Number"><input
                                type="tel" name="phone" onChange={(e) => {
                                setPhoneNumber(e.target.value);
                            }}  required={true} value={phoneNumber} /></span>
                        </div>
                        <div className="col-12">
                            <span className="field_block_inputs position-relative"
                                  data-title="Write your message"><textarea name="message" id=""
                                                                            rows={4} onChange={(e) => {
                                setMessage(e.target.value);
                            }} value={message}  /></span>
                        </div>
                        <div className="col-12 pt-2 text-center">
                            <input type="submit" value="Submit" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>;
}

export default ContactUs;
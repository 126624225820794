import Api from "./Api";
import BBEndpoints from "../core/config/Endpoints";

export default class OrderService {
    async createRazorpayOrder(id: string) {
        const token = localStorage.getItem("token") || '';
        let res = await Api.get(
            `${BBEndpoints.deal}/${id}/create-order`, {
                "Content-Type": "application/json",
                "Authorization": token,
            });
        return res['orderId'];
    }

    async verifyOrder(id: string, payment: any) {
        const token = localStorage.getItem("token") || '';
        return await Api.post(`${BBEndpoints.deal}/${id}/verify-order`, payment, {
            "Content-Type": "application/json",
            "Authorization": token,
        });
    }

    async generateCoupon(id: string) {
        const token = localStorage.getItem("token") || '';
        return await Api.get(`${BBEndpoints.coupon}/${id}/generate`, {
            "Content-Type": "application/json",
            "Authorization": token,
        });
    }

    async orders(page: number, limit: 100) {
        const token = localStorage.getItem("token") || '';
        return await Api.get(`${BBEndpoints.order}`, {
            "Content-Type": "application/json",
            "Authorization": token,
        }, {
            page: page,
            limit: limit,
        });
    }
}
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function GalleryType4({content}: {content: any}) {
    const [redirectUrl, setRedirectUrl] = useState('');

    useEffect(() => {
        if (content['type'] === 'Deal') {
            setRedirectUrl(`/deal/${content['item']}`)
        } else  if (content['type'] === 'Coupon') {
            setRedirectUrl(`/coupon/${content['item']}`)
        } else if (content['type'] === 'Store') {
            setRedirectUrl(`/store/${content['item']}`)
        }
    }, [content]);

    return <div className="brands_block">
        <Link to={redirectUrl}>
            <img src={content.image} alt="" srcSet=""/>
            <span>{content.title}</span>
        </Link>
    </div>;
}

export default GalleryType4;
import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import ListService from "../../services/ListService";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import SearchHistoryService from "./SearchHistoryService";
import UserService from "../Authentication/Service";

const Search = ({show, handleClose}: {show: boolean, handleClose: VoidFunction}) => {
    const commonState = useSelector((state: any) => state.common);
    const userState = useSelector((state: any) => state.auth);
    const locationState = useSelector((state: any) => state.location);
    const [title, setTitle] = useState('');
    const [listService] = useState(new ListService());
    const [searchHistoryService] = useState(new SearchHistoryService());
    const [offers, setOffers] = useState<any[]>([]);
    const [stores, setStores] = useState<any[]>([]);
    const [trendingSearches, setTrendingSearches] = useState<any[]>([]);
    const [recentSearches, setRecentSearches] = useState<any[]>([]);

    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const getOffers = async () => {
        const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
        if (location) {
            const deals = await listService.deals(location._id, {page: 1, limit: 50, superSaver: false, search: title});
            const coupons = await listService.coupons(location._id, {page: 1, limit: 50, superSaver: false, search: title});
            const offers = shuffleArray([...deals, ...coupons]);
            setOffers(offers);
        }
    }

    const getStores = async () => {
        const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
        if (location) {
            const stores = await listService.stores(location._id, {page: 1, limit: 50, search: title});
            setStores(stores);
        }
    }

    const addSearchHistory = async () => {
        const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
        console.log(location);
        if (location) {
            await searchHistoryService.addSearchHistory(title, location._id);
        }
    }

    const getTrendingSearches = async () => {
        const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
        if (location) {
            const trendingSearches = await searchHistoryService.getTrendingSearches(location._id);
            setTrendingSearches(trendingSearches);
        }
    }

    const getRecentSearches = async () => {
        const recentSearches = await searchHistoryService.getRecentSearches();
        if (recentSearches) {
            setRecentSearches(recentSearches);
        }
    }

    useEffect(() => {
        if (title.length >= 3) {
            getOffers();
            getStores();
            addSearchHistory();
        } else if (title.length == 0) {
            setOffers([]);
            setStores([]);
        }
    }, [title]);

    useEffect(() => {
        if (show) {
            getTrendingSearches();
            getRecentSearches();
        }
    }, [show])

    return <>
        <Modal className="full_width_popup search_modal modal fade" show={show} id="search_popup"
             aria-labelledby="search_popupLabel" aria-hidden="true">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
            <Modal.Body className="modal-dialog modal-fullscreen bg-white">
                <div className="modal-content modal_content">
                    {/* <div className="modal-body"> */}
                        <div className="container">
                            <div className="search_pophead">
                                <input type="search" name="search" id="search"
                                       placeholder="Search for stores, coupons & offers..." value={title} onChange={(e) => setTitle(e.target.value)} />
                                    <p className="text-end after_search_line">What do you want to search today?</p>
                            </div>
                            <div className="trending_items">
                                <div className="site_headline">
                                    <h2>Trending Now</h2>
                                </div>
                                <div className="searchlist_items">
                                    {trendingSearches.map((search, key) => <a href="javascript:void(0)" key={key} onClick={() => {
                                        setTitle(search);
                                    }}>{search}</a>)}
                                </div>
                            </div>
                            {userState.token !== null && <div className="trending_items">
                                <div className="site_headline">
                                    <h2>Recent Search's</h2>
                                </div>
                                <div className="searchlist_items">
                                    {recentSearches.map((search, key) => <a href="javascript:void(0)" key={key} onClick={() => {
                                        setTitle(search);
                                    }}>{search}</a>)}
                                </div>
                            </div>}
                            {stores.length > 0 && <div className="search_items_blocks">
                                <div className="row align-items-center">
                                    <h5 className="col-8">""<span className="search_text">{title}</span>"" in stores (<span
                                        className="search_text_count">{stores.length}</span>)</h5>
                                    <div className="col-4 text-end">
                                        {/*<a href="#" className="view_more_link">View all</a>*/}
                                    </div>
                                </div>
                                <div className="searchlist_items">
                                    {stores.map((store, key) => <Link to={`/store/${store._id}`} onClick={() => {
                                        handleClose();
                                        setTitle('');
                                    }}  key={key}>{store.name}</Link>)}
                                </div>
                            </div>}

                            {offers.length > 0 && <div className="search_items_blocks">
                                <div className="row align-items-center">
                                    <h5 className="col-8">""<span className="search_text">{title}</span>"" in offers (<span
                                        className="search_text_count">{offers.length}</span>)</h5>
                                    <div className="col-4 text-end">
                                        {/*<a href="#" className="view_more_link">View all</a>*/}
                                    </div>
                                </div>
                                <div className="searchlist_items">
                                    {offers.map((offer, key) => <Link to={offer.code ? `/coupon/${offer._id}` : `/deal/${offer._id}`} onClick={() => {
                                        handleClose();
                                        setTitle('');
                                    }} key={key}>{offer.title}</Link>)}
                                </div>
                            </div>}
                        </div>
                    {/* </div> */}
                </div>
            </Modal.Body>
        </Modal>
    </>;
}

export default Search;
import {Link} from "react-router-dom";

const CategoryType1 = ({item}: {item: any}) => {
    return <div className="category_block">
        <Link to={`/category/${item.slug}`}>
            <img src={item.image} alt="" srcSet=""/>
            <h5>{item.title}</h5>
        </Link>
    </div>;
}

export default CategoryType1;
import {createSlice} from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const initialState = {categories: [], tags: [], locations: [], homeContent: [], offerDealsContent: [], categoryContent: [], configs: []};

const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		categoriesLoaded(state, action) {
			state.categories = action.payload;
		},
		locationsLoaded(state, action) {
			state.locations = action.payload;
		},
		homeContentLoaded(state, action) {
			state.homeContent = action.payload;
			localStorage.setItem('homeContent', JSON.stringify(action.payload));
		},
		offerDealsContentLoaded(state, action) {
			state.offerDealsContent = action.payload;
			localStorage.setItem('offerDealsContent', JSON.stringify(action.payload));
		},
		categoryContentLoaded(state, action) {
			state.categoryContent = action.payload;
			localStorage.setItem('categoryContent', JSON.stringify(action.payload));
		},
		configsLoaded(state, action) {
			state.configs = action.payload;
		},
	},
});

export const {categoriesLoaded, homeContentLoaded, offerDealsContentLoaded, categoryContentLoaded, locationsLoaded, configsLoaded} = commonSlice.actions;
export default commonSlice.reducer;

import {Modal} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";

const CategoryModel = ({title, show, handleClose}: {title: String, show: boolean, handleClose: VoidFunction}) => {
    const commonState = useSelector((state: any) => state.common);
    const location = useLocation();

    return <Modal className="full_width_popup modal fade" show={show} id="all_subcategories"  role="dialog" aria-labelledby="all_subcategoriesLabel" aria-hidden="true">
        <button type="button" className="btn-close" onClick={() => handleClose()}></button> 
        <Modal.Body className="modal-dialog modal-fullscreen bg-white" role="document">
            <div className="modal-content modal_content">
                {/* <div className="modal-body"> */}
                    <div className="container">
                        <div className="site_headline">
                            <h2>{title}</h2>
                        </div>
                        <div className="all_categories">
                            {commonState.categories.filter((cat: any) => !cat.parent).map((cat: any) => <div className='category_block'>
                                <Link to={`/category/${cat.slug}`} onClick={() => handleClose()}>
                                    <img src={cat.image} alt='' srcSet=''/>
                                    <h5>{cat.title}</h5>
                                </Link>
                            </div>)}
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </Modal.Body>
    </Modal>;
}

export default CategoryModel;

import moment from "moment";
import {useState} from "react";
import OrderDetail from "../modules/Order/OrderDetail";

const OrderTile = ({order}: { order: any }) => {
    const [showDetails, setShowDetails] = useState(false);
    return <>
        <tr>
            <td data-title="Order ID">#{order.orderId}</td>
            <td data-title="Order Type">{order.item.type}</td>
            <td data-title="Date"> {moment(order.createdAt).format('DD MMM YYYY')}</td>
            <td data-title="Amount">₹{order.total}</td>
            <td data-title="Status" className="status_td"><span className="completed status_order">Completed</span></td>
            <td>
                <div className="view_data">
                    <a href="javascript:void(0);" className="view_orderdetail" onClick={() => setShowDetails(true)}><i
                        className="fa-solid fa-eye"></i></a>

                </div>
            </td>
        </tr>
        <OrderDetail order={order} show={showDetails} handleClose={() => setShowDetails(false)} />
    </>;
}

export default OrderTile;
import {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import BBConstants from "../core/config/Constants";
import Script from 'react-load-script';
import {useSelector} from "react-redux";
import store from "../core/store";
import {locationUpdated} from "../core/store/location/reducers";
import {useNavigate} from "react-router";


function LocationPicker() {
    const [mapLoaded, setMapLoaded] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const locationState = useSelector((state: any) => state.location);
    const navigate = useNavigate();

    const getCurrentLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                error => {
                    console.error(error);
                }
            );
        } else {
            toast.error('Geolocation is not supported by this browser.');
        }
    }

    const handleSearch = async () => {
        if (searchQuery.length === 0) return;

        const autocomplete = new google.maps.places.AutocompleteService();
        autocomplete.getQueryPredictions({
            input: searchQuery,
            ...(latitude && longitude && {location: new google.maps.LatLng(latitude ?? 0, longitude ?? 0), radius: 1000}),
        }, (res) => {
            if (res) {
                // @ts-ignore
                setSearchResults(res);
            }
        });
    };

    const changeLocation = (loc: any) => {
        console.log('****',loc);
        let locality, city, state, address;
        address = loc.description;
        for (let x = 0; x < loc.terms.length; x++) {
            switch (loc.types[x]) {
                case 'sublocality_level_1':
                    locality = loc.terms[x]['value'];
                    break;
                case 'sublocality':
                    city = loc.terms[x]['value'];
                    break;
                case 'locality':
                    city = loc.terms[x]['value'];
                    break;
                case 'political':
                    state = loc.terms[x]['value']
                    break;
                case 'administrative_area_level_1':
                    city = loc.terms[x]['value']
                    break;
            }
        }
        store.dispatch({
            type: locationUpdated,
            payload: {
                address,
                city,
                locality,
                state
            }
        });
        toast.success('Location updated successfully');
        if (city == 'Delhi') {
            navigate(`/${city.toLowerCase()}`);
        } else {
            navigate(`/${city.toLowerCase()}-${state.toLowerCase()}`);
        }
    }


    useEffect(() => {
        if (mapLoaded) {
            handleSearch();
        }
    }, [searchQuery, latitude, longitude, mapLoaded]);

    return <>
        <Script url={`https://maps.googleapis.com/maps/api/js?key=${BBConstants.googleSearchApiKey}&libraries=places`} onLoad={() => setMapLoaded(true)} />
        <div className="modal fade" id="location_slide" tabIndex={-1} role="dialog"
             aria-labelledby="location_slideLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-slideout" role="document">
                <div className="modal-content modal_content">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body">
                        <div className="location_fields_row_modal">
                            <form action="" method="get"
                                  className="m-0 position-relative location_form_modal d-flex align-items-center justify-content-between flex-wrap ">
                                <input type="text" name="location" id='location_input_modal' className="search_input"
                                       placeholder="Enter your location"
                                       onChange={(e) => setSearchQuery(e.target.value)}/>
                                {latitude && longitude && <a href="javascript:void(0);" className="locate_me" onClick={getCurrentLocation}>
                                    <i className="fa-solid fa-location-crosshairs"></i>
                                    <div className="get_current_locate">
                                        <h6>Enable GPS for accurate results</h6>
                                    </div>
                                </a>}
                                <ul className="all_result search_result">
                                    {searchResults.map((loc, key) => <li key={key} onClick={() => changeLocation(loc)}>
                                        <a href="javascript:void(0);" className="result_locate">
                                            <i className="fa-solid fa-location-dot"></i>
                                            <div className="result_locate_name">
                                                <h6>{loc['structured_formatting']['main_text']}</h6>
                                                <p>{loc['description']}</p>
                                            </div>
                                        </a>
                                    </li>)}
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default LocationPicker;

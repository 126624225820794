import Api from "../../services/Api";
import BBEndpoints from "../../core/config/Endpoints";

export default class StoreService {
    async details(id: string, location: string, coordinates?: any, outlet?: string) {
        const data = {
            "location": location, ...(coordinates && {coordinates: coordinates.toMap()}),
            ...(outlet && {outlet: outlet})
        };

        return await Api.post(`${BBEndpoints.store}/${id}`, data, {"Content-Type": "application/json"});
    }

    async photos(id: string) {
        return await Api.get(`${BBEndpoints.store}/${id}/photos`, {"Content-Type": "application/json"});
    }

    async outlets(id: string) {
        return await Api.get(`${BBEndpoints.store}/${id}/outlets`, {"Content-Type": "application/json"});
    }

    async couponDetails(id: string) {
        return await Api.get(`${BBEndpoints.coupon}/${id}`, {"Content-Type": "application/json"});
    }

    async dealDetails(id: string) {
        return await Api.get(`${BBEndpoints.deal}/${id}`, {"Content-Type": "application/json"});
    }
}

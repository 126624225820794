import {Modal} from "react-bootstrap";
import moment from "moment";
import React from "react";

const ItemDetailModal = ({item, show, handleClose}: { item: any, show: boolean, handleClose: VoidFunction }) => {
    return <Modal className="modal fade" id="store_tandc_modal p-0 m-0" role="dialog"
                  show={show} size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => handleClose()}></button>
        <Modal.Body className="tandc_content bg-white" role="document">
            {/* <div className="modal-content modal_content p-0 m-0"> */}
            {/* <div className="modal-body tandc_content"> */}
            <div className="simple_content">
                <h5>Details</h5>
                <p>{item.description ? item.description : item.shortDescription}</p>
            </div>
            <div className="simple_content deals_validity">
                <h5>Validity</h5>
                <p><i className="fa-solid fa-calendar-days pe-2"></i>Redeemable for <strong>all days.</strong>
                </p>
                {item.expiryDate &&
                    <p><i className="fa-solid fa-clock pe-2"></i><strong>Expires</strong> on <strong>
                        {moment(item.expiryDate).format('DD MMM YYYY')}
                    </strong></p>}
            </div>
            <div className="simple_content redeem_process">
                <h4>How to redeem</h4>
                <ul>
                    <li>Download the SellOnFly App (Google Play store or iPhone AppStore).</li>
                    <li>Select the city and check out all the stores in your city 🡪 Select the store and
                        go to store page 🡪 Select the deal/Deals you wish to have and avail it.
                    </li>
                    <li>Book your Deal by not paying any amount OR making a payment as stated.
                    </li>
                    <li>After completing the full payment, review the QR code located in your Profile
                        under the Orders section.
                    </li>
                    <li>Show the QR code in your profile to the vendor to avail the eligible deal.
                    </li>
                    <li>To avail the membership card deal, show your digital card from your profile,
                        including QR code provided, to redeem deals on offline stores.
                    </li>
                </ul>
            </div>
            <div className="simple_content redeem_process">
                <h4>Terms & Conditions</h4>
                <ul>
                    <li>Kindly go through Terms & Conditions carefully to avoid any misunderstanding.
                    </li>
                    <li>It is advisable to call and confirm the offer before actually visiting the
                        store
                    </li>
                    <li>Offers valid for all users unless otherwise mentioned.

                    </li>
                    <li>The images are for representation purpose only.
                    </li>
                    <li>These offers, vouchers, coupons, membership are valid for people aged 18 years
                        and above.
                    </li>
                    <li>Vouchers can be redeemed only once.

                    </li>
                    <li>Vouchers will not be replaced if lost or stolen.
                    </li>
                    <li>Vouchers cannot be clubbed with in-store discounts & discounts from other
                        platforms.
                    </li>
                    <li>Discount may not be applicable on combos, other special items.
                    </li>
                    <li>Discount may not be applicable on MRP Items.
                    </li>
                    <li>The store may levy Government taxes & service charge as per its discretion.
                    </li>
                    <li>It is advisable to make prior reservations by calling before visiting the store.
                    </li>
                </ul>
            </div>
            {/* </div> */}
            {/* </div> */}
        </Modal.Body>
    </Modal>;
}

export default ItemDetailModal;
import TitleBanner from "../../components/TitleBanner";
import {Helmet} from "react-helmet";
import React from "react";

function HowItWorks() {
    return <>
        <Helmet>
            <title>SellOnFly: Your Ultimate Local Business Destination & Exclusive Deals Hub</title>
            <meta name="title"
                  content={`SellOnFly: Your Ultimate Local Business Destination & Exclusive Deals Hub`}/>
            <meta name="description"
                  content="Explore SellOnFly: Your go-to for local business info & exclusive deals. Enjoy incredible offers from trusted stores in your city every time you shop."/>
        </Helmet>
        <TitleBanner title="How it works" />
        <section className="pt80 pb80">
            <div className="container">
                <div className="content_text max1024 mx-auto">
                    <div className="row steps_row align-items-stretch">
                        <div className="col-md-4 steps_block">
                            <div className="text-md-center">
                                <span className="step_count">1</span>
                                <img src="/assets/images/explore.png" alt="" srcSet="" />
                                    <h3>EXPLORE</h3>
                                    <p>Amazing Offers Running On Trusted Stores In Your City Or Locality.</p>
                            </div>
                        </div>
                        <div className="col-md-4 steps_block">
                            <div className="text-md-center">
                                <span className="step_count">2</span>
                                <img src="/assets/images/buy.png" alt="" srcSet="" />
                                    <h3>BUY</h3>
                                    <p>Show Your Interest In The Offers You Like And Confirm It To The Seller.</p>
                            </div>
                        </div>
                        <div className="col-md-4 steps_block">
                            <div className="text-md-center">
                                <span className="step_count">3</span>
                                <img src="/assets/images/diskette.png" alt="" srcSet="" />
                                    <h3>SAVE</h3>
                                    <p>On Everything, Every time You Make A Purchase.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>;
}

export default HowItWorks;
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import StoreService from "./Service";
import {useSelector} from "react-redux";
import ListService from "../../services/ListService";
import ItemStandard from "../../components/ItemStandard";
import TimingsModal from "../../components/modals/TimingsModal";
import Slider from "react-slick";
import BBPay from "./BBPay";

const Store = () => {
    const navigate = useNavigate();
    const {id, outlet} = useParams();
    const storeService = useMemo(() => new StoreService(), []);
    const [store, setStore] = useState(null);
    const locationState = useSelector((state: any) => state.location);
    const commonState = useSelector((state: any) => state.common);
    const [showCallDetails, setShowCallDetails] = useState(false);
    const [listService] = useState(new ListService());
    const [offers, setOffers] = useState<any[]>([]);
    const [showTimings, setShowTimings] = useState(false);
    const [photos, setPhotos] = useState<any[]>([]);
    const [outlets, setOutlets] = useState<any[]>([]);
    const sliderSettings = {
        autoplay: false,
        fade: false,
        draggable: true,
        autoplaySpeed: 3000,
        speed: 400,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        focusOnSelect: true,
    };

    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const getStoreDetails = async () => {
        if (commonState.locations.length > 0 && locationState.city) {
            const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
            let _store = await storeService.details(id ?? '', location._id, locationState.coordinates, outlet);
            setStore(_store);
            await getOffers();
        }
    }

    const getStorePhotos = async () => {
        let photos = await storeService.photos(id ?? '');
        setPhotos(photos);
    }

    const getStoreOutlets = async () => {
        let outlets = await storeService.outlets(id ?? '');
        setOutlets(outlets);
    }


    const getOffers = async () => {
        const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
        const deals = await listService.deals(location._id, {page: 1, limit: 20, store: id, superSaver: false});
        const coupons = await listService.coupons(location._id, {page: 1, limit: 20, store: id, superSaver: false});
        console.log(deals, coupons);
        const offers = shuffleArray([...deals, ...coupons]);
        setOffers(offers);
    };


    useEffect(() => {
        getStoreDetails();
        getStorePhotos();
        getStoreOutlets();
    }, [id, commonState, locationState, outlet]);

    return store !== null ? <>
        <section className="banner_section">
            <div className="container">
                <div className="store_top_row">
                    {photos.length > 0 && <div className="store_banner">
                        <Slider {...sliderSettings} className='banner_slider'>
                            {photos.map((photo: any) => <div className="banner_slide only_img">
                                <img src={photo.image}
                                     alt="" srcSet=""/>
                            </div>)}
                        </Slider>
                    </div>}
                    <div className="store_all_details">
                        <h1 className="store_name">
                            <span>{store['name']}</span>
                            {/*<img src="images/verified_store.png" className="verified_store" alt="" srcSet=""/>*/}
                        </h1>
                        <div className="store_location"><span><i
                            className="fa-solid fa-location-dot pe-2"></i>{store['outlet']['address']}</span>
                        </div>
                        {/*<a href="#ratingid" className="store_rating"><i className="fa-solid fa-star pe-1"></i>4.4</a>*/}
                        {/*<div className="meet_seller_btn"><a href="#meetstore">Meet The Seller</a></div>*/}
                        <div className="short_description">
                            <p>{store['description']}</p>
                        </div>
                        <div className="cta_btns">
                            <a href="javascript:void(0);" className="call_tab" onClick={() => setShowCallDetails(!showCallDetails)}><i
                                className="fa-solid fa-phone pe-2"></i>Call</a>
                            <a href={`https://wa.me/${store['outlet']['phoneNumbers'][0]}?text=Hello`} className="whatsapp_btn"><i className="fa-brands fa-whatsapp pe-2"></i>Whatsapp</a>
                            {store['outlet']['timings'] &&
                                <a href="javascript:void(0);" className="opening_hours" onClick={() => setShowTimings(true)}>Timings<i
                                    className="ps-2 fa-solid fa-angle-down"></i></a>}


                            <div className="call_details" style={{display: showCallDetails ? 'block' : 'none'}}>
                                <h5>Call Now</h5>
                                {(store['outlet']['phoneNumbers'] as any[]).map((mobile: string) => <p>
                                    <span>+91-{mobile}</span><a href={`tel:+91-${mobile}`}><i
                                    className="fa-solid fa-phone"></i></a></p>)}
                            </div>
                        </div>
                        {/* <BBPay store={store}/> */}
                    </div>
                </div>
            </div>
        </section>

        {outlets.length > 1 && <section className="outlet_sec">
            <div className="container">
                <div className="home_categories outlets_row">
                    {outlets.map((outlet: any) => <div className="category_block text-center">
                        <Link to={`/store/${store['_id']}/outlet/${outlet._id}`}>
                            <img src={store['logo']} alt="" srcSet=""/>
                            <h5>{outlet['address'].toString().slice(0, 20)}...</h5>
                        </Link>
                    </div>)}
                    {outlets.length > 7 && <div className="see_all_btn text-center">
                        <Link to={'#'} data-bs-toggle="modal" data-bs-target="#all_outlets">
                            <img src="/assets/images/store.png" alt="" srcSet=""/>
                            <h5>See All Outlets</h5>
                            <img src="/assets/images/angle-double-right.png" alt="" srcSet=""/>
                        </Link>
                    </div>}
                </div>
            </div>
        </section> }

        {
            offers.length > 0 && <section className="category_offers pt80 pb80">
                <div className="container">
                    <div className="site_headline row align-items-center">
                        <div className="col-md-12 bb_time_title">
                            <h2>Offers</h2>
                        </div>
                    </div>
                    <div className="deals_grid">
                        {offers.map((item) => <ItemStandard item={item}/>)}
                    </div>
                    <div className="text-center bottom_viewmore mt-4">
                        <a href="javascript:void(0)" className="view_more_link" onClick={() => {
                            navigate(`/view-all/standard/store/${store['_id']}`);
                        }}>View More<i className="ps-1 fa-solid fa-angle-down"></i></a>
                    </div>
                </div>
            </section>
        }
        {store['outlet']['timings'] && <TimingsModal show={showTimings} handleClose={() => setShowTimings(false)} timing={store['outlet']['timings']}/>}
    </> : <></>;
}

export default Store;

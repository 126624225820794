import Api from "./Api";
import BBEndpoints from "../core/config/Endpoints";

export default class ListService {
    async stores(location: any, options: { search?: any, superSaver?: boolean, type?: any, categories?: any; tags?: any; coordinates?: any; excludedIds?: any; page?: number | undefined; limit?: number | undefined; }) {
        let {search, superSaver, type, categories, tags, coordinates, excludedIds, page = 1, limit = 100} = options;
        let data = {
            "search": search,
            "location": location,
            "tags": tags || [],
            "categories": categories || [],
            "excluded_ids": excludedIds || [],
            "type": type,
            ...(coordinates && {coordinates: coordinates}),
            ...(superSaver && {superSaver: superSaver}),
        };

        return await Api.post(BBEndpoints.stores, data, {
            "Content-Type": "application/json",
        }, {
            "page": `${page}`,
            "limit": `${limit}`
        });
    }

    async deals(location: any, options: { search?: any, categories?: any, superSaver?: boolean, tags?: any, coordinates?: any, excludedIds?: any, store?: any, page?: number | undefined, limit?: number | undefined, }) {
        let {search, superSaver, categories, tags, coordinates, excludedIds, store, page = 1, limit = 100} = options;
        let data = {
            "search": search,
            "location": location,
            "tags": tags || [],
            "categories": categories || [],
            "excluded_ids": excludedIds || [],
            ...(coordinates && {coordinates: coordinates}),
            ...(store && {store: store}),
            ...(superSaver && {superSaver: superSaver}),
        };

        return await Api.post(BBEndpoints.deals, data, {
            "Content-Type": "application/json",
        }, {
            "page": `${page}`,
            "limit": `${limit}`
        });
    }

    async coupons(location: any, options: { search?: any, superSaver?: boolean, categories?: any; tags?: any; coordinates?: any; excludedIds?: any; store?: any; page?: number | undefined; limit?: number | undefined; }) {
        let {search, superSaver, categories, tags, coordinates, excludedIds, store, page = 1, limit = 100} = options;
        let data = {
            "search": search,
            "location": location,
            "tags": tags || [],
            "categories": categories || [],
            "excluded_ids": excludedIds || [],
            ...(coordinates && {coordinates: coordinates}),
            ...(store && {store: store}),
            ...(superSaver && {superSaver: superSaver}),
        };

        return await Api.post(BBEndpoints.coupons, data, {
            "Content-Type": "application/json",
        }, {
            "page": `${page}`,
            "limit": `${limit}`
        });
    }

    async vouchers(location: any, options: { search?: any, categories?: any, superSaver?: boolean, tags?: any, coordinates?: any, excludedIds?: any, store?: any, page?: number | undefined, limit?: number | undefined, }) {
        let {search, superSaver, categories, tags, coordinates, excludedIds, store, page = 1, limit = 100} = options;
        let data = {
            "search": search,
            "location": location,
            "tags": tags || [],
            "categories": categories || [],
            "excluded_ids": excludedIds || [],
            ...(coordinates && {coordinates: coordinates}),
            ...(store && {store: store}),
            ...(superSaver && {superSaver: superSaver}),
        };

        return await Api.post(BBEndpoints.vouchers, data, {
            "Content-Type": "application/json",
        }, {
            "page": `${page}`,
            "limit": `${limit}`
        });
    }
}

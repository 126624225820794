// src/components/Sitemap.tsx

import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Sitemap: React.FC = () => {
  const location = useLocation();

  // Define your routes
  const routes = [
    "/",
    "/offers-deals",
    "/about-us",
    "/terms-of-use",
    "/how-it-works",
    "/faq",
    "/contact-us",
    "/partner",
    "/blogs",
    "/category",
    "/store",
    "/gold-membership",
    "/deal",
    "/orders",
    "/view-all",
    "/blogs/:id",
    "/category/:slug",
    "/category/:mainSlug/:slug",
    "/store/:id",
    "/store/:id/outlet/:outlet",
    "/view-all/:type/:filter/:id",
    "/deal/:id",
    "/coupon/:id",
    "/vouchers",
  ]; // Add more routes as needed

  // Generate the XML content
  const xml = `
    <?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${routes
        .map(
          (route) => `
        <url>
          <loc>${`${process.env.REACT_APP_BASE_URL || ""}${route}`}</loc>
          <lastmod>${new Date().toISOString()}</lastmod>
        </url>
      `
        )
        .join("")}
    </urlset>
  `;

  console.log(xml);

  return (
    <Helmet>
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${process.env.REACT_APP_BASE_URL || ""}/`}
      />
      {routes.map((route) => (
        <link
          key={route}
          rel="alternate"
          hrefLang="en"
          href={`${process.env.REACT_APP_BASE_URL || ""}${route}`}
        />
      ))}
      <link
        rel="sitemap"
        type="application/xml"
        title="Sitemap"
        href={`${process.env.REACT_APP_BASE_URL || ""}${
          location.pathname
        }/sitemap.xml`}
      />
      <link
        rel="stylesheet"
        type="application/xml"
        title="Sitemap"
        href={`${process.env.REACT_APP_BASE_URL || ""}${
          location.pathname
        }/sitemap.xml`}
      />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="content-language" content="en" />
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
      <meta
        http-equiv="cache-control"
        content="public, max-age=0, must-revalidate"
      />
      <meta http-equiv="pragma" content="public, max-age=0, must-revalidate" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="last-modified" content="${new Date().toUTCString()}" />
      <meta name="revisit-after" content="7 days" />
    </Helmet>
  );
};

export default Sitemap;

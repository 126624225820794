import Api from "./Api";
import BBEndpoints from "../core/config/Endpoints";
import store from "../core/store";
import {categoriesLoaded, configsLoaded, locationsLoaded} from "../core/store/common/reducers";

export default class CommonService {
    async getCategories() {
        let page = 1;
        let limit = 100;
        const categories = []
        while (page !== 0) {
            const _categories = await Api.get(BBEndpoints.categories, {}, {
                page: `${page}`,
                limit: `${limit}`,
            });
            categories.push(..._categories);
            page++;
            if (_categories.length === 0) {
                page = 0;
            }
        }
        store.dispatch({
            type: categoriesLoaded,
            payload: categories,
        })

    }

    async getLocations() {
        let page = 1;
        let limit = 100;
        const locations = []
        while (page !== 0) {
            const _locations = await Api.get(BBEndpoints.locations, {}, {
                page: `${page}`,
                limit: `${limit}`,
            });
            locations.push(..._locations);
            page++;
            if (_locations.length === 0) {
                page = 0;
            }
        }
        store.dispatch({
            type: locationsLoaded,
            payload: locations,
        })

    }

    async getConfigs() {
        let page = 1;
        let limit = 100;
        const configs = []
        while (page !== 0) {
            const _configs = await Api.get(BBEndpoints.configs, {}, {
                page: `${page}`,
                limit: `${limit}`,
            });
            configs.push(..._configs);
            page++;
            if (_configs.length === 0) {
                page = 0;
            }
        }
        store.dispatch({
            type: configsLoaded,
            payload: configs,
        })

    }
}
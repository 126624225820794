import React from "react";
import {Modal} from "react-bootstrap";
import QRCode from "react-qr-code";
import moment from "moment/moment";


const OrderDetail = ({order, show, handleClose}: { order: any, show: boolean, handleClose: VoidFunction }) => {
    return <Modal className="modal p-0" id="orderdetails_modal" role="dialog"
                aria-labelledby="orderdetailsLabel" aria-hidden="true" show={show} centered>
        <Modal.Body className="modal-dialog modal-dialog-centered bg-white p-0 m-0" role="document">
            <div className="modal-content modal_content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
                <div className="modal-body _content">
                    <div className="deal_details_top">
                        {
                            order.item.type === 'Coupon' &&  <>
                                <div className="qr_code text-center">
                                    <QRCode
                                        style={{ height: "100", width: "100", backgroundColor: "transparent" }}
                                        value={order.item.code}
                                    />
                                </div>
                                <div className="deal_details_top_one text-center">
                                    <h5 className="v_c_d">Coupon code</h5>
                                    <p className="deal_code">{order.item.code}</p>
                                </div>
                            </>
                        }
                        {/*<div className="purchase_at text-center">*/}
                        {/*    <h5>Purchase at</h5>*/}
                        {/*    <p>24-f formaen colony RTM, dadabari, kota</p>*/}
                        {/*</div>*/}
                        <div className="feel_free_to_call d-flex align-items-center justify-content-between flex-wrap">
                            <h5 className="text-center">Feel free to call for any query</h5>
                            <p className="text-center"><a href="tel:7820991614">
                                <img src="/assets/images/call.png" alt="" srcSet="" />
                                    <span>+91-7820991614</span>
                            </a></p>
                        </div>
                        <div
                            className="purchase_date_price d-flex align-items-center justify-content-between flex-wrap">
                            <div className="purchase_on text-center">
                                <h5>Purchase on</h5>
                                <p>{moment(order.createdAt).format('MMM DD, YYYY')}</p>
                            </div>
                            <div className="purchase_price text-center">
                                <span>₹{order.total}</span>
                            </div>
                        </div>
                    </div>
                    <div className="deal_name_detail">
                        <h5>{order.item.title}</h5>
                        <p className="text-capitalize">Valid @ {order.store?.name} Only</p>
                        {order.item.type === 'Coupon' && <p><i className="fa-solid fa-calendar-days pe-2"></i>Redeemable for <strong>all days.</strong>
                        </p>}
                        {order.item.expiryDate &&
                            <p><i className="fa-solid fa-clock pe-2"></i><strong>Expires</strong> on <strong>
                                {moment(order.item.expiryDate).format('DD MMM YYYY')}
                            </strong></p>}
                    </div>
                    <div className="tnc_details_tab blog_tabs px-0">
                        <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tnc-tab" data-bs-toggle="tab" href="#tnc" role="tab"
                                   aria-controls="tnc" aria-selected="true">Terms & Conditions</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="orderdetails-tab" data-bs-toggle="tab" href="#orderdetails"
                                   role="tab" aria-controls="orderdetails" aria-selected="false">Details</a>
                            </li>
                        </ul>
                        <div className="tab-content content_text" id="myTabContent">
                            <div className="tab-pane fade" id="tnc" role="tabpanel"
                                 aria-labelledby="tnc-tab">
                                <h4>Terms & Conditions</h4>
                                <ul>
                                    <li>Offers valid for all users.</li>
                                    <li>The images are for representation purpose only.</li>
                                    <li>These offers, vouchers, coupons, membership are valid for people aged 18 years and
                                        above.
                                    </li>
                                    <li>Vouchers can be redeemed only once.</li>
                                    <li>Vouchers will not be replaced if lost or stolen.</li>
                                    <li>Vouchers cannot be clubbed with in-store discounts & discounts from other platforms.
                                    </li>
                                    <li>Discount may not be applicable on combos, other special items.</li>
                                    <li>Discount may not be applicable on MRP Items.</li>
                                    <li>The store may levy Government taxes & service charge as per its discretion.</li>
                                    <li>It is advisable to make prior reservations by calling before visiting the store.</li>
                                </ul>
                            </div>
                            <div className="tab-pane fade" id="orderdetails" role="tabpanel"
                                 aria-labelledby="orderdetails-tab">
                                <h4>Details</h4>
                                {order.item.type === 'Coupon' ? order.item.details : order.item.shortDescription}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>;
}

export default OrderDetail;

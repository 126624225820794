import {useSelector} from "react-redux";
import TitleBanner from "../../components/TitleBanner";
import React, {useState} from "react";
import UserService from "../Authentication/Service";
import {toast} from "react-hot-toast";
import store from "../../core/store";
import {logoutSuccess} from "../../core/store/auth/reducers";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";

const RequestAccountDeletion = () => {
    const userState = useSelector((state: any) => state.auth);
    const [userService] = useState(new UserService());
    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        toast.loading('Deleting your account...');
        const res = await userService.deleteAccount();
        toast.dismiss();
        if (res) {
            toast.success('Your account has been deleted successfully.');
            store.dispatch({
                type: logoutSuccess,
            });
            navigate('/');
        }
    }

    return (
        <>
            <Helmet>
                <title>SellOnFly: Account deletion, user delete account request page, data removal option available.</title>
                <meta name="title"
                      content={`SellOnFly: Account deletion, user delete account request page, data removal option available.`}/>
                <meta name="description"
                      content="Request account deletion on SellOnFly. Follow our guidelines to securely remove your account and personal information from our system."/>
            </Helmet>
            <TitleBanner title={'Request Account Deletion'}/>
            {/*    Check if user is logged in or not */}
            {
                userState.token === null ? <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center align-item-center justify-content-center mt-5">
                                Please login to continue.
                            </div>
                        </div>
                    </div>
                </div> : <section className="my_account_row pt60 pb60">
                    <div className="container">
                        <div className="userprofile_details">
                            <h3 className="acc_title">Confirm Deletion</h3>
                            <form onSubmit={handleSubmit} className="m-0 row">

                                <div className="col-lg-3 col-md-5 col-7 mx-auto">
                                    <div className="update_btn">
                                        <input type="submit" value="Delete Account"/>
                                    </div>
                                </div>
                                <p className="text-center mt-3 text-danger">Once deleted, all your data will be removed from the database including account details, orders etc.</p>
                            </form>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default RequestAccountDeletion;

import Api from "../../services/Api";
import BBEndpoints from "../../core/config/Endpoints";

export class PartnerRequestService {

    async request({name, email, mobileNumber, location, intro}: {name: string, email: string, mobileNumber: string, location: string, intro?: string}) {
        return await Api.post(BBEndpoints.partnerRequest, {
            name,
            email,
            mobileNumber,
            location,
            intro
        }, {
            "Content-Type": "application/json"
        })
    }
}
import React, {useState} from "react";
import TitleBanner from "../../components/TitleBanner";
import {useSelector} from "react-redux";
import {toast} from "react-hot-toast";
import {Helmet} from "react-helmet";
import {PartnerRequestService} from "./Service";

const Partner = () => {
    const service = new PartnerRequestService();
    const locations = useSelector((state: any) => state.common.locations);
    const [name, setName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [about, setAbout] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const res = await service.request({name, email, location: city, mobileNumber: phoneNo, intro: about});
        if (res != null) {
            toast.success('Thank you for your interest. We will get back to you soon.');
            setName('');
            setPhoneNo('');
            setEmail('');
            setCity('');
            setAbout('');
        }
    }

    return (
        <>
            <Helmet>
                <title>SellonFly, partner with us, local businesses, premier saving app, online shopping experience,
                    call us for business partnership</title>
                <meta name="title"
                      content={`SellonFly, partner with us, local businesses, premier saving app, online shopping experience, call us for business partnership`}/>
                <meta name="description"
                      content="Partner with SellonFly, India's premier saving app for local businesses. Join us to create an exceptional online shopping experience and enhance your business presence."/>
            </Helmet>
            <TitleBanner title={'Seller Registration/List Your Business'}/>
            <section className="buisness_register_sec pt60 pb60">
                <div className="container">
                    <form onSubmit={handleSubmit} className="buisness_register_form max1024 mx-auto">
                        <div className="user_details_fields row field_block">
                            <div className="site_headline pb-md-4 pb-2 col-12 max700">
                                <h2 className="pb-md-1 pb-2">India's Premier Saving App for Local Businesses</h2>
                                <p>Welcome to SellonFly, where we enable our partners to join forces with us to create
                                    an exceptional online shopping experience.</p>
                            </div>
                            <div className="col-md-6">
                                <span className="field_block_inputs position-relative"
                                      data-title="Enter your full name"><input type="text" name="full-name" value={name}
                                                                               onChange={(e) => setName(e.target.value)}
                                                                               required={true}/></span>
                            </div>
                            <div className="col-md-6">
                                <span className="field_block_inputs position-relative"
                                      data-title="Enter your mobile number"><input type="tel" name="phone-no"
                                                                                   value={phoneNo}
                                                                                   onChange={(e) => setPhoneNo(e.target.value)}/></span>
                            </div>
                            <div className="col-md-6">
                                <span className="field_block_inputs position-relative"
                                      data-title="Enter your email address"><input type="email" name="email"
                                                                                   value={email}
                                                                                   onChange={(e) => setEmail(e.target.value)}
                                                                                   required={true}/></span>
                            </div>
                            <div className="col-md-6">
                            <span className="field_block_inputs position-relative" data-title="Select Location">
                                <select className="buisness_type w-100" value={city}
                                        onChange={(e) => setCity(e.target.value)} required={true}>
                                    <option value="">Select City</option>
                                    {locations.filter((loc: {
                                        type: any;
                                    }) => loc.type === 'City').map((location: any) => {
                                        return <option value={location.title}>{location.title}</option>
                                    })}
                                </select>
                            </span>
                            </div>
                            <div className="col-md-12">
                                <span className="field_block_inputs position-relative"
                                      data-title="Tell us about yourself"><textarea name="" id=""
                                                                                    rows={5} value={about}
                                                                                    onChange={(e) => setAbout(e.target.value)}></textarea></span>
                                <p className="word_limit">0/100</p>
                            </div>
                            <div className="col-12 pt-2 text-center">
                                <input type="submit" value="Submit"/>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}

export default Partner;

import {Modal} from "react-bootstrap";

const TimingsModal = ({timing, show, handleClose}: { timing: any, show: boolean, handleClose: VoidFunction }) => {
    return <Modal className="modal fade" id="opening_hours_modal" show={show} role="dialog" aria-labelledby="opening_hoursLabel" aria-hidden="true" centered>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
        <Modal.Body className="bg-white w-100" role="document">
            {/* <div className="modal-content modal_content"> */}
                {/* <div className="modal-body"> */}
                    <div className="site_headline">
                        <h2>Opening Hours</h2>
                    </div>
                    <div className="hours_days_list">
                        <table>
                            <tr>
                                <th>Monday</th>
                                {!timing['monday']['closed'] ? <td>{timing['monday']['start']} - {timing['monday']['end']}</td> : <td className='text-danger'>Closed</td>}
                            </tr>
                            <tr>
                                <th>Tuesday</th>
                                {!timing['tuesday']['closed'] ? <td>{timing['tuesday']['start']} - {timing['tuesday']['end']}</td> : <td className='text-danger'>Closed</td>}
                            </tr>
                            <tr>
                                <th>Wednesday</th>
                                {!timing['wednesday']['closed'] ? <td>{timing['wednesday']['start']} - {timing['wednesday']['end']}</td> : <td className='text-danger'>Closed</td>}
                            </tr>
                            <tr>
                                <th>Thursday</th>
                                {!timing['thursday']['closed'] ? <td>{timing['thursday']['start']} - {timing['thursday']['end']}</td> : <td className='text-danger'>Closed</td>}
                            </tr>
                            <tr>
                                <th>Friday</th>
                                {!timing['friday']['closed'] ? <td>{timing['friday']['start']} - {timing['friday']['end']}</td> : <td className='text-danger'>Closed</td>}
                            </tr>
                            <tr>
                                <th>Saturday</th>
                                {!timing['saturday']['closed'] ? <td>{timing['saturday']['start']} - {timing['saturday']['end']}</td> : <td className='text-danger'>Closed</td>}
                            </tr>
                            <tr>
                                <th>Sunday</th>
                                {!timing['sunday']['closed'] ? <td>{timing['sunday']['start']} - {timing['sunday']['end']}</td> : <td className='text-danger'>Closed</td>}
                            </tr>
                        </table>
                    </div>
                {/* </div> */}
            {/* </div> */}
        </Modal.Body>
    </Modal>;
}

export default TimingsModal;
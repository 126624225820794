import {useLocation} from "react-router-dom";
import React, {useEffect, useRef} from "react";
import TitleBanner from "../../components/TitleBanner";
import {Helmet} from "react-helmet";

function TermsOfUse() {
    const location = useLocation();
    const scrollToRef = useRef(null);

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return <>
        <Helmet>
            <title>SellOnFly, website, application Terms of Use, agreement.</title>
            <meta name="title"
                  content={`SellOnFly, website, application Terms of Use, agreement.`}/>
            <meta name="description"
                  content="Terms of Use agreement between you and SellOnFly Private Limited governing your use of www.SellOnFly.com or the SellonFly mobile application."/>
        </Helmet>
        <TitleBanner title="Terms of use" />
        <section className="">
            <div className="container">
                <div className="content_text max1024 mx-auto pt60">
                    <p><b>Agreement Between You and SELLONFLY PRIVATE LIMITED</b> Thank you for visiting
                        www.SellonFly.com or the SellonFly mobile application (together the "SellonFly")</p>
                    <p>SellonFly (brand) is owned and operated by SellOnFly Private Limited, a company incorporated
                        under the provisions of the Companies Act, 2013 with its registered office at 311, Silver Wings
                        Apartments, Dadabari Extension, Kota, Rajasthan.</p>
                    <p>These <b>Terms of Use</b> govern you, the User of SellonFly, and your conduct, regardless of the
                        means of access. You represent and warrant you possess the capacity and legal right to enter
                        into this Agreement and to use this Website in accordance with the terms and conditions herein.
                        You also acknowledge and agree that, unless we specifically provide otherwise, this Agreement
                        only applies to this site and our online activities and does not apply to any of our offline
                        activities.</p>
                    <p>By using the various services available on the SellonFly ("Services"), you consent to these
                        terms, SellonFly's Privacy Policy, and any community guidelines and supplemental terms provided
                        to you for the Services that you use (collectively, "Terms") and SellonFly's efforts to improve
                        every User's experience on the platform. Please read the Terms carefully, as they form the
                        entire agreement between you and SellonFly.</p>

                    <h3>Usage of Services</h3>
                    <ul>
                        <li>Posting content on the Platform:
                            <ol type="a">
                                <li>you shall be required to log on using your registered mobile number/ username/email
                                    address/Facebook account and password.
                                </li>
                                <li>you may upload/post: (i) content that either was created by you entirely or legally
                                    belongs to you, or you have a legally valid license which permits you to upload/post
                                    the content; or (ii) your reviews, comments, reactions, etc., to specific content on
                                    the Platform (the content/information covered under (i) and (ii) above, the
                                    "Content"), and if in the event we suffer any loss or damages on account of the
                                    Content you have uploaded/posted belonging to another party or such Content
                                    violating or infringing the rights of any party, you shall be liable to indemnify us
                                    for such loss and damages;
                                </li>
                                <li>by uploading/posting any Content, and in consideration of you using the Services,
                                    you automatically constitute you granting a royalty-free, perpetual, irrevocable,
                                    transferrable, worldwide license to SellonFly to (a) use, reproduce, modify, adapt,
                                    publish, translate, create derivative works from, distribute, perform, transmit and
                                    display such Content (in whole or part) and/or to incorporate it in other works in
                                    any form, media, mode of delivery or technology now known or later developed, (b)
                                    display online or offline your Content (or part thereof, or any modification,
                                    adaptation, translation or derivative works thereof) online and offline and permit
                                    others (including without limitation SellonFly's co-brand partners) to do the same,
                                    and (c) permit other users to access, reproduce, distribute, publicly display,
                                    prepare derivative works of, and publicly perform your content via the Services, as
                                    may be permitted by the functionality of those Services (e.g., for users to re-blog,
                                    re-post or download your content). Further, the license you have granted to us as
                                    aforesaid shall not lapse notwithstanding that we have not used, distributed,
                                    displayed, published, or adapted/modified any of the Content for any duration.
                                </li>
                                <li>you shall, under no circumstances, upload any Content that: (i) belongs to another
                                    person and to which you do not have any right; (ii) is grossly harmful, harassing,
                                    blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of
                                    another's privacy, hateful, or racially, ethnically objectionable, disparaging,
                                    relating or encouraging money laundering or gambling, or otherwise unlawful in any
                                    manner; (iii) harm minors in any way; (iv) infringes any patent, trademark,
                                    copyright or other proprietary rights; (v) violates any law for the time being in
                                    force; (vi) deceives or misleads the reader/viewer about the origin of any
                                    information, or contains any information that is grossly offensive or menacing in
                                    nature; (vii) impersonate another person; (viii) contain software viruses or any
                                    other computer code, files or programmes that is designed to interrupt, destroy or
                                    limit the functionality of any computer resource; (ix) threatens the unity,
                                    integrity, defence security or sovereignty of India, friendly relations with foreign
                                    states or public order or causes incitement to the commission of any cognizable
                                    offence or prevents investigation of any offence or is insulting any other nation.
                                </li>
                                <li>While SellonFly does not make any editorial determination about the Content to be
                                    displayed and made accessible on the Platform, if: (i) we receive any notice or
                                    communication that any Content or any part of the Content belongs to a party who has
                                    not consented to such content being uploaded and accessible on the Platform or that
                                    it is violative of the rights of any person who has not consented to such Content
                                    being uploaded and accessible on the Platform; or (ii) it comes to our attention
                                    that any of the Content violates these Terms, we shall be entitled to remove such
                                    content/block public access to such content either temporarily or permanently,
                                    subject to the provisions of the Indian Copyright Act, 1957, and the Information
                                    Technology Act, 2000, and as we deem fit or in compliance with the orders or
                                    directions of the court (if applicable) and we shall have the right, at our
                                    discretion, to remove your account, and in either case you shall have no right to
                                    make any claims against us for removing or blocking such Content;
                                </li>
                                <li>you understand that we act as a technological platform providing incidental,
                                    transient storage of the content uploaded by you for the purpose of electronic
                                    transmission to and access by members of the general public as understood under the
                                    Indian Copyright Act, 1957 and as an intermediary as understood under the Indian
                                    Information Technology Act, 2000.
                                </li>
                                <li>you shall be solely responsible for safeguarding your login details including
                                    username and password and that we shall have no liability whatsoever.
                                </li>
                            </ol>
                        </li>
                        <li>Authority. You agree that you are permitted to use the Services under applicable law. If you
                            are accessing an account(s) on behalf of the account owner (e.g. as an administrator,
                            consultant, analyst, etc.), the Terms apply to your activities on behalf of the account
                            owner.
                        </li>
                        <li>Age. If you are under the age of majority as per the laws of your country of residence
                            (“Minimum Age”) you may not register for an account. SellonFly does not include any
                            age-inappropriate content. User data is processed by SellonFly through verification and
                            other internal checks, in a manner that both protects any user information as well as
                            advances the best interests of children. Parents/adult guardians are required to
                            monitor/regulate the content being viewed by their wards who have not attained the Minimum
                            Age. Parental control tools available from third party vendors/service providers might be
                            useful in this regard. It shall also be responsibility of parents/adult guardians to ensure
                            that their wards do not view Content that is not appropriate for them.
                        </li>
                        <li>Member Conduct. You agree not to use the Services to:
                            <ol type="a">
                                <li>obtain or attempt to obtain unauthorized access to the Services or to SellonFly's
                                    servers, systems, network, or data;
                                </li>
                                <li>make available any Content that is harmful to children, threatening, abusive,
                                    harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's
                                    privacy, hateful, or racially, ethnically, or otherwise objectionable;
                                </li>
                                <li>violate any applicable laws or regulations;</li>
                                <li>impersonate any person or entity; or forge or manipulate headers or identifiers to
                                    disguise the origin of any content transmitted through the Service;
                                </li>
                                <li>make available any Content that you do not have the right to make available or that
                                    infringes any patent, trademark, trade secret, copyright or other proprietary rights
                                    of any person or entity;
                                </li>
                                <li>post Content containing advertisements or other commercial solicitations without our
                                    prior written permission;
                                </li>
                                <li>make available viruses or any other computer code, files, programs or Content
                                    designed to interrupt, destroy or limit the functionality of the Services or affect
                                    other users; or
                                </li>
                                <li>interfere with or disrupt the Services or servers, systems or networks connected to
                                    the Services in any way.
                                </li>
                            </ol>
                        </li>
                        <li>Use of Services. You must follow any guidelines or policies associated with the use of the
                            Services. You must not misuse or interfere with the Services or try to access them using a
                            method other than the interface and the instructions that we provide. You may use the
                            Services only as permitted by law. Unless otherwise expressly stated, you may not access or
                            reuse the Services, or any portion thereof, for any commercial purpose. Without our express
                            written consent, you may not (a) use any automated means to access the Platform or collect
                            any information from the Platform (including without limitation robots, spiders or scripts),
                            or (b) frame the Platform (or any part thereof), place pop-up windows over the Platform (or
                            any part thereof) pages, or otherwise affect the display of part or area of the Platform.
                        </li>
                        <li>Anti-Corruption Laws. You agree to comply with all applicable anti-corruption laws including
                            laws that prohibit unlawful payments to anyone for a corrupt purpose in relation to these
                            Terms.
                        </li>
                        <li>Ownership and Reuse. Subject to these Terms, using the Services does not give you ownership
                            of any intellectual or other property rights or interests in the Services or the Content you
                            access. You must not use any branding or logos used in the Services unless SellonFly has
                            given you separate explicit written permission. You may not remove, obscure, or alter any
                            legal notices displayed on the Platform or in relation to any Content. Unless you have
                            explicit written permission, you must not reproduce, modify, rent, lease, sell, trade,
                            distribute, transmit, broadcast, publicly perform, create derivative works based on, or
                            exploit for any commercial purposes, any portion of the Content or use of, or access to, the
                            Services (including Content, advertisements, APIs, and software).
                        </li>
                        <li>Support. Unless otherwise expressly stated, SellonFly does not promise to provide you with
                            any support for the Services. If SellonFly does provide you with support, it is at
                            SellonFly 's sole discretion and does not mean that we will continue to provide you with
                            support in the future.
                        </li>
                        <li>Fees. SellonFly reserves the right to charge fees for use of or access to the Services (and
                            any associated support), whether currently in existence or not, in SellonFly's sole
                            discretion. If SellonFly decides to charge fees SellonFly's payment terms will apply and
                            SellonFly will provide you with prior notice.
                        </li>
                        <li>Anti-Abuse Policy. SellonFly prohibits sending unsolicited emails or messages using the
                            Services. You may not in connection with the Services engage in commercial activity on
                            non-commercial properties or apps or high-volume activity without SellonFly's prior written
                            consent. You may not engage in conduct or activity that is disruptive to the Services or the
                            experience of other users.
                        </li>
                    </ul>

                    <h3>Your Account Notices</h3>
                    <ul>
                        <li>Account Information. You may need an account to use some Services. You must ensure that your
                            account information (that is, the information you provided when you registered for or
                            subscribed to a Service) remains current, complete, accurate and truthful. All SellonFly
                            accounts are non-transferable, and any rights to them terminate upon the account holder's
                            death. In order to create an account we may collect and keep possession of personal
                            information such as your name, email address/Facebook user name, username, and your password
                            ("Personal Data"). By providing us with the same, you acknowledge and accept that we have
                            your consent for the collection and processing of such Personal Data. Our possession and use
                            of Personal Data shall be in accordance with our Privacy Policy and Terms.
                        </li>
                        <li>Access to Your Account. You are responsible for all activity that happens on or through your
                            account. To protect your account, keep your password confidential. Do not reuse your account
                            password with other services. If you forget your password and otherwise cannot validate your
                            account to SellonFly, you acknowledge and agree that your account may be inaccessible to
                            you and that all data associated with the account may not be retrievable.
                        </li>
                        <li>Notices. SellonFly may provide you with notices, including service announcements and
                            notices regarding changes to these Terms, by, but not limited to, email, regular mail, text
                            message or SMS, MMS, push notification or in-app message, postings on the Services,
                            telephone, or other reasonable means now known or hereafter developed. You consent to
                            receive these notices by any and all of the foregoing means. You may not receive notices if
                            you violate the Terms by accessing the Services in an unauthorized manner, and you will be
                            deemed to have received any and all notices that would have been delivered had you accessed
                            the Services in an authorized manner.
                        </li>
                    </ul>

                    <h3>Procedure for Copyright or Other Intellectual Property Infringement Claims or Claims of
                        Violation of Other Rights</h3>
                    <ul>
                        <li>We respect the intellectual property as well as privacy of others, and we expect our users
                            to do the same in respect to their use of and activities on the SellonFly platform. While a
                            substantial amount of content on the SellonFly platform is user generated over which we do
                            not exercise editorial control, we may, in appropriate circumstances and at our sole
                            discretion subject to applicable law, block access to such content or delete such content,
                            or disable, terminate, and/or take other appropriate steps relating to the accounts of users
                            who may be infringers. While we do not make any editorial determination about the user
                            generated content being posted and displayed and made accessible on the SellonFly platform,
                            if: (i) we receive any notice or communication that any content or any part of the content
                            belongs to a party who has not consented to such content being uploaded and accessible on
                            the SellonFly platform or that it is violative of the rights of any person who has not
                            consented to such content being uploaded and accessible on the SellonFly platform; or (ii)
                            it comes to our attention that any of the content violates any of the terms and conditions
                            applicable to the SellonFly platform, we shall be entitled to remove such content/block
                            public access to such content either temporarily or permanently, subject to the provisions
                            of the Indian Copyright Act, 1957, and the Information Technology Act, 2000, and as we deem
                            fit or in compliance with the orders or directions of the court (if applicable) and we shall
                            have the right, at our discretion, to remove the infringer's account, and in either case
                            none of our users, partners, clients or vendors (including you) shall have no right to make
                            any claims against us for removing or blocking such content;
                        </li>
                        <li>Reporting Claims of Copyright Infringement If you believe that your work (or the work of a
                            third party on whose behalf you are entitled to act) has been copied in a way that
                            constitutes copyright infringement, please file a copyright infringement notice with us. The
                            notice should be in writing and include, in the order set out below, the following:
                            <ol type="a">
                                <li>A statement that you have identified material on the Platform that infringes your
                                    copyright (or infringes the copyright of a third party on whose behalf you are
                                    entitled to act);
                                </li>
                                <li>A description of the copyrighted work that you claim has been infringed, which
                                    should include the type of work (such as a book or a sound recording) and any
                                    relevant further details (such as the title and date of publication, as applicable);
                                </li>
                                <li>The country or countries to which your copyright applies;</li>
                                <li>A description of the way in which the copyright material has been infringed;</li>
                                <li>A description of where the material that you claim is infringing is located on our
                                    services (including a screen shot and link to the same);
                                </li>
                                <li>Your address, telephone number, and email address so that we may get in contact with
                                    you;
                                </li>
                                <li>A statement by you that you have a good faith belief that the disputed use of the
                                    copyrighted work is not authorized by the copyright owner (or by a third party who
                                    is legally entitled to do so on behalf of the copyright owner) and is not otherwise
                                    permitted by law;
                                </li>
                            </ol>
                        </li>
                        <li>Reporting claims of violation of other personal rights If you believe that any Content on
                            the Platform is violative of your (or that of someone you know and are authorized to act
                            for) privacy or other personal rights please follow the following instructions.
                            <ol type="a">
                                <li>A statement that you have identified material on the Platform that infringes your
                                    copyright (or infringes the copyright of a third party on whose behalf you are
                                    entitled to act);
                                </li>
                                <li>A description of the way in which the copyright material has been infringed;</li>
                                <li>A description of where the material that you claim is infringing is located on our
                                    services (including a screen shot);
                                </li>
                                <li>Your address, telephone number, and email address so that we may get in contact with
                                    you;
                                </li>
                                <li>A statement by you, made under penalty of perjury, that the notice is accurate.</li>
                            </ol>
                        </li>
                        <li>A complaint can be submitted by contacting us at <a
                            href="mailto:contact@SellonFly.com">contact@SellonFly.com</a> Please be sure to include
                            responses to items i-viii of Clause (b) above (if the complaint is regarding copyright
                            infringement) or i-v of Clause (c) above (if the complaint is regarding the violation of
                            other personal rights). If you are unsure whether there has been an infringement of your
                            copyright or about your rights in the material, we suggest that you seek legal advice before
                            reporting the material to us or sending us a counter-notice. Our response to your notice is
                            regulated by applicable law. There may be negative consequences if you falsely allege
                            copyright infringement or report material to us in bad faith. In addition, we may, in
                            appropriate circumstances and at our discretion, disable, terminate, and/or take other
                            appropriate steps relating to the accounts of users who may be repeat infringers.
                        </li>
                    </ul>

                    <h3>Content in the Services and License Grant to SellonFly</h3>
                    <ul>
                        <li>Content. Our Services display content that is not SellonFly's. This content is the sole
                            responsibility of the entity or person that makes it available. SellonFly assumes no
                            responsibility for the conduct of third Parties, including persons or entities with which
                            you communicate using the Services. Many of the Services enable you to submit content. You -
                            not SellonFly - are entirely responsible for any content that you upload, post, email,
                            transmit, or otherwise make available via the Services. We may remove and refuse to display
                            content that violates the Terms or applicable laws or regulations, but that does not mean
                            that we monitor the Services or review or screen any content. By using or accessing the
                            Services you understand and agree that you may be exposed to offensive, indecent, or
                            objectionable content.
                        </li>
                        <li>IP Ownership and License Grant. Except as otherwise provided in the specific guidelines for
                            a Service, when you upload, share with or submit content to the Services you retain
                            ownership of any intellectual property rights that you hold in that content and you
                            automatically grant SellonFly a worldwide, royalty-free, non-exclusive, perpetual,
                            irrevocable, transferable, sublicensable license to (a) use, host, store, reproduce, modify,
                            prepare derivative works (such as translations, adaptations, summaries or other changes),
                            communicate, publish, publicly perform, publicly display, and distribute this content (in
                            whole or in part, and /or incorporate in any other works) in any manner, mode of delivery,
                            technology or media now known or developed in the future; (b) display your Content (or part
                            thereof, or any modification, adaptations, translations or derivative works thereto) online
                            and offline and permit other (including without limitation SellonFly's co-brand partners)
                            and (c) permit other users to access, reproduce, distribute, publicly display, prepare
                            derivative works of, and publicly perform your content via the Services, as may be permitted
                            by the functionality of those Services (e.g., for users to re-blog, re-post or download your
                            content). You must have the necessary rights to grant us the license described in this
                            Section 6(b) for any content that you upload, share with or submit to the Services.
                        </li>
                    </ul>

                    <h3>Modifying and Terminating the Services; Terminating Accounts; Amendment of the Terms of Use</h3>
                    <ul>
                        <li>We are constantly innovating, changing and improving the Services. We may, without notice,
                            add or remove functionalities or features, create new limits to the Services, or temporarily
                            or permanently suspend or stop a Service.
                        </li>
                        <li>You can stop using the Services at any time. You may cancel and delete your account at any
                            time here. However, you continuing to use the app constitutes your consent to us collecting
                            data, mentioned within the terms, and processing the same.
                        </li>
                        <li>You can withdraw consent to the use and/or processing of your personal data by contacting us
                            at <a href="mailto:contact@SellonFly.com">contact@SellonFly.com</a> and we shall remove
                            the same shortly.
                        </li>
                        <li>We may temporarily or permanently suspend or terminate your account or impose limits on or
                            restrict your access to parts or all of the Services at any time, without notice and for any
                            reason, including, but not limited to, violation of these Terms, court order, or inactivity.
                        </li>
                        <li>If your account is terminated, access to your username, password, and all related
                            information, files, and content associated with your account may be terminated and your
                            username may be recycled for use by others. However, some information may be retained in our
                            files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our
                            Terms of Use and/or comply with legal requirements.
                        </li>
                        <li>We may amend these Terms at any time by informing you of the amended terms via email to your
                            email address. Such amendments will be effective when we email a notice of the amendments to
                            you. Alternatively or additionally, we may display amended terms to you when you access the
                            Platform, in which case such terms shall be effective when they are posted on the Platform.
                        </li>
                    </ul>

                    <h3>Force Majeure</h3>
                    <ul>
                        <li>Without prejudice to any other provision herein, SellonFly shall not be liable for any
                            loss, damage or penalty as a result of any delay in or failure to deliver or otherwise
                            perform hereunder due to any cause beyond SellonFly's control, including, without
                            limitation, acts of the User or other Users, embargo or other governmental act, regulation
                            or request affecting the conduct of SellonFly's business, fire, explosion, accident, theft,
                            vandalism, riot, acts of war, strikes or other labour difficulties, lightning, flood,
                            windstorm or other acts of God.
                        </li>
                    </ul>

                    <h3>Our Warranties and Disclaimers</h3>
                    <ul>
                        <li>To The Fullest Extent Permitted By Applicable Law, SellonFly, Its Parent Companies,
                            Subsidiaries, Affiliates, Officers, Directors, Employees, Contractors, Agents, Partners,
                            Licensors And Distributors (Collectively “SellonFly Entities”) Do Not Make Any
                            Representations, Promises, Or Warranties, Express Or Implied, About The Services. We Provide
                            Our Services “As-Is,” “With All Faults,” And “As Available.” Your Use Of The Services,
                            Including Content Within The Services, Is At Your Own Risk And We Do Not Represent, Promise,
                            Or Warrant That The Services Will Be Uninterrupted, Timely, Secure, Or Error-Free. You
                            Understand And Agree That No Data Transmission Over The Internet Or Information Storage
                            Technology Can Be Guaranteed To Be Secure, And SellonFly Expressly Disclaims Any
                            Warranties, Express Or Implied, To That Effect. We Make No Commitments, Promises Or
                            Warranties About The Content Within The Services Or Content Linked From The Services, The
                            Support We Provide For The Services, The Specific Functions Of The Services, The Security Of
                            The Services, Or The Services' Reliability, Quality, Accuracy, Availability, Or Ability To
                            Meet Your Needs, Provide Certain Outputs Or Achieve Certain Results.
                        </li>
                        <li>Some Jurisdictions Provide For Certain Implied Warranties, Such As The Implied Warranty Of
                            Merchantability, Fitness For A Particular Purpose And Non-Infringement. To The Fullest
                            Extent Permitted By Applicable Law, We Disclaim Any And All Implied Or Express Promises Or
                            Warranties About The Services.
                        </li>
                    </ul>

                    <h3>Limitation of Liability</h3>
                    <ul>
                        <li>To the fullest extent permitted by applicable law, you agree and understand that SellonFly
                            entities will not be liable for: any indirect, special, incidental, consequential, treble or
                            other multiples of damages, exemplary or punitive damages arising from or in connection with
                            these terms or your use of the services. SellonFly entities are not responsible for any
                            lost profits, lost revenues, lost business opportunities, diminution in value, including any
                            alleged loss or diminution in value of personal information, or any other losses
                            (collectively, “losses”) arising from or in connection with these terms or your use of or
                            access to the services, including, but not limited to, losses resulting from or in
                            connection with: the deletion of, alteration of, mis-delivery of, or failure to store data
                            maintained or transmitted by the services; the limiting, suspension or termination of your
                            account; your downloading or sharing of information, including personal information, via the
                            services; the unauthorized access to your account or any data maintained or transmitted by
                            the services; links provided by the services or third parties to external sites or
                            resources; your dealings with or participation in promotions of advertisers found on or
                            through the services; or any good or services sold by such advertisers. SellonFly entities
                            will not be liable for problems caused by others, the wrongful or unlawful actions of third
                            parties, or an act of god. The limitations and exclusions in these terms will apply whether
                            or not SellonFly has been advised of or should have been aware of the possibility of any
                            losses arising.
                        </li>
                        <li>To the fullest extent permitted by law, SellonFly entities are not liable in connection
                            with any disputes that arise out of or relate to these terms or services for any amount
                            greater than the amount you paid to us for the services.
                        </li>
                    </ul>

                    <h3>Community Standards and Guidelines</h3>
                    <ul>
                        <li>Our primary objective is to empower people to discover and engage with other people, places
                            and communities in their neighbourhood. We believe that You, as a User, play the most
                            important role in helping us do this every day - this is your platform. Every day, people
                            come to SellonFly to share their moments, discover and connect with new friends and earn
                            rewards for spreading more magic, subject to reasonable restrictions.
                        </li>
                        <li>SellonFly is about local, social communities. This is the reason we recommend all users to
                            post, share and recommend content specific to places and experiences in their neighbourhood.
                            The follow guidelines will help everyone build a better community around them:
                            <ol type="a">
                                <li>We recommend you to complete your profile - this will help other users discover, and
                                    engage with you based on similar interests.
                                </li>
                                <li>The User is encouraged to post and share your moments at restaurants, salons, clubs,
                                    and other interesting places in your locality, with their stories in caption. Please
                                    always add the relevant location in context to the post.
                                </li>
                                <li>Putting up posts which capture the essence of the place and their experiences will
                                    help your fellow users understand the outlet better.
                                </li>
                                <li>The User is also encouraged to ask questions about different places and experiences
                                    and get feedback and reviews from co-users on the platform.
                                </li>
                                <li>Liking posts randomly (people at their homes, not in proper dresses, hazy pictures,
                                    not looking at the camera) is discouraged.
                                </li>
                                <li>Comments to a post not pertaining to or related to the post, person, place or their
                                    review on the experience are also discouraged. Please try and make the comments
                                    interesting so that other users also look up to you and want to stay in touch with
                                    you.
                                </li>
                                <li>Posting any misleading links in comments, and content that expresses obscenity,
                                    vulgarity, hatred, racism, or gratuitous violence is prohibited, and will face
                                    disciplinary action, including but not limited to permanent ban.
                                </li>
                            </ol>
                        </li>
                        <li>General Code of Conduct: SellonFly wishes for each User to have a safe and pleasant
                            experience while on our platform. As a user of the SellonFly app you should uphold these
                            following codes of conduct inter alia, subject to periodic review and change by SellonFly,
                            as this is your app and your community:
                            <ol type="a">
                                <li>You will not threaten, stalk, defame, defraud, degrade, victimize or intimidate an
                                    individual or group of individuals for any reason.
                                </li>
                                <li>You are responsible for any interaction with other users.</li>
                                <li>You agree that you will not collect any personal information of other SellonFly
                                    users.
                                </li>
                                <li>You must not use the service for any illegal purpose.</li>
                                <li>You must not try to restrict other users from using the app.</li>
                                <li>You may not reproduce, copy, or redistribute the design or layout of this website or
                                    mobile application, individual elements of the website or mobile application design,
                                    or our logos without our express written permission.
                                </li>
                            </ol>
                        </li>
                    </ul>

                    <div ref={scrollToRef}  id="privacy_policy">
                        <h3>Privacy Policy</h3>
                        <p>The information we learn from customers helps us personalise and continually improve your
                            shopping experience at SellonFly. We use your information to assist in handling a variety
                            of matters including but not related to, communicate with you about vouchers, products,
                            services and promotional offers, update our records and generally maintain your accounts
                            with us, display content and recommend merchandise and services that might be of interest to
                            you. We also use this information to improve our platform, prevent or detect fraud or abuses
                            of our website and enable you to have a grand experience. We use Cookies and Device Data
                            that allow us to connect your activity within the Platform with other information we store
                            about you in your profile or as related to your interactions with the Site. We collect
                            information to provide better services to all our users - from figuring out basic stuff such
                            as which language you speak, to more complex things like which services you find helpful or
                            which services are available in your current location. The information SellonFly collects,
                            and how that information is used, depends on how frequently you use our services and how you
                            manage your privacy controls internally within your device. We use the information we
                            collect to customise our services for you, including providing recommendations and
                            personalised content. We will ask for your explicit consent to share any sensitive personal
                            information. Additionally, we will also ask for your consent before using your information
                            for a purpose that isn't covered in this Privacy Policy.</p>
                        <ul>
                            <li><b>User Information</b>
                                <ol type="a">
                                    <li>The Application obtains the information you provide when you download and
                                        register the Application.
                                    </li>
                                    <li>When you register with us and use the Application, you generally provide (a)
                                        your name, email address, phone number and other registration information; (b)
                                        transaction-related information, such as when you make purchases and/or upload a
                                        selfie, respond to any offers, or download or use any applications from us; (c)
                                        information you provide us when you contact us for help; (d) any other
                                        information you enter into our system when using the Application, such as
                                        contact information and project management information.
                                    </li>
                                    <li>The Application may also collect certain information automatically, including,
                                        but not limited to, the type of mobile device you use, your mobile devices
                                        unique device ID, circle of the IP address of your mobile device, your mobile
                                        operating system, the type of mobile Internet browsers you use, and information
                                        about the way you use the Application.
                                    </li>
                                    <li>We may use the information you provided us to contact you from time to time to
                                        provide you with important information, required notices and marketing
                                        promotions and also improve your activities on our platform such as posting in
                                        our forums, liking posts, sending feedback, and responding to surveys. If you
                                        choose to share data about yourself via your profile, chat, or other interactive
                                        areas of the Application, please be advised that all data you disclose in these
                                        areas is public and your data will be accessible to anyone who accesses the
                                        Application. SellonFly shall not be liable for any data that you had chosen to
                                        disclose yourself in this public space.
                                    </li>
                                    <li>We may also use your personal information to improve our services and user
                                        experience on the Application, to enforce our legal rights as permitted under
                                        law, and otherwise with your consent.
                                    </li>
                                    <li>You may restrict our access to such information by terminating your account on
                                        SellonFly or removing SellonFly's permission to access the same by directly
                                        disabling the same on your phone. The User may note that the latter may severely
                                        restrict SellonFly's ability to provide a personalised feel to the Platform.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Geo-Location Information</b>
                                <ol type="a">
                                    <li>We collect information about your location when you use our services, which
                                        helps us in determining the merchants and outlets near you where SellonFly
                                        vouchers can be used or cashback can be availed. Your location can be determined
                                        with varying degrees of accuracy by: (a) GPS; (b) IP address; (c) Sensor data
                                        from your device; (d) Information about things near your device, such as Wifi
                                        Access Points, cell towers and Bluetooth enabled devices.
                                    </li>
                                    <li>We may request access or permission to and track location-based information from
                                        your mobile device while you are using the Application, to provide
                                        location-based services. We may also collect the precise location of your device
                                        when the app is running in the foreground, background, or even if you are not
                                        directly using the application depending on your device and its settings. Your
                                        device, as well as the SellonFly mobile app, provides you with options to
                                        control how and when we collect your geolocation. This is only to better the
                                        User's experience on the SellonFly Platform.
                                    </li>
                                    <li>You may be able to disallow our use of certain location data through your device
                                        or browser settings, for example, by disabling “Location” services for the
                                        SellonFly application in your device privacy settings. By allowing SellonFly
                                        access to the same the User acknowledges and accepts that SellonFly shall be
                                        aware of the User's Geo-Location at any time. If you wish to change our access
                                        or permissions, you may do so in your device's settings.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Contacts Information</b>
                                <ol type="a">
                                    <li>Please note that no other contacts information other than the phone numbers and
                                        thereto attached names and email addresses will be collected and used from Your
                                        address book. Other numbers or information that may be contained in your address
                                        book will be filtered away by our safety algorithms and will therefore not be
                                        collected by SellonFly. Please also note that You can always choose not to
                                        share Contacts Information with SellonFly.
                                    </li>
                                    <li>Friends on SellonFly help you discover the coolest things in town. From best
                                        food outlets to best picnic spots, your connects keep you up to date with latest
                                        trends.
                                    </li>
                                    <li>When you turn on phone contacts uploading, information from your phone contacts
                                        will be continuously uploaded. This makes it easier to find the people you
                                        already know.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Financial Information</b>
                                <ol type="a">
                                    <li>Availing the services offered by SellonFly can at times involve the collection
                                        of sensitive financial information including but not limited to linking with
                                        mobile wallets, entering credit/debit card details, password, etc to facilitate
                                        payment for the respective service. You acknowledge and accept that linking
                                        SellonFly with relevant mobile wallet or bank account helps in making the
                                        experience better and thereby expressly consent to SellonFly's collection and
                                        subsequent processing of such financial data.
                                    </li>
                                    <li>The financial information we learn and gather from you is used to link
                                        SellonFly with your preferred mobile wallet, verify your identity to permit you
                                        to undertake transactions (including to facilitate and process payments) in
                                        connection with SellonFly, communicate with you about any promotional offers,
                                        services or updates associated with SellonFly, conduct analysis on the users'
                                        spending capability and generally maintain your accounts with us. No information
                                        collated while carrying out the above is shared with or sold to a third party by
                                        SellonFly and is strongly protected. You acknowledge and accept that the above
                                        activities that involve SellonFly's use of your financial information is to
                                        improve your experience on the SellonFly platform and hereby provide SellonFly
                                        consent for the same.
                                    </li>
                                    <li>We work to protect the security of your information during transmission by
                                        encrypting all financial information, including passwords, you input in addition
                                        to maintaining security of such information. User authentication is handled by
                                        SellonFly through authentication tokens that is reset on every fresh one-time
                                        password validation. We reveal only the last four digits of your credit / debit
                                        card numbers while making payment. Of course, we transmit the entire credit /
                                        debit card number to the appropriate credit / debit card company or the
                                        concerned bank for processing of the transaction.
                                    </li>
                                    <li>We maintain physical, electronic and procedural safeguards in connection with
                                        the collection, storage and disclosure of such sensitive personal information.
                                    </li>
                                    <li>You may disable SellonFly's access to the same remotely on the SellonFly
                                        Platform or contact us at <a
                                            href="mailto:contact@SellonFly.com">contact@SellonFly.com</a></li>
                                </ol>
                            </li>
                            <li><b>Health Information</b>
                                <ol type="a">
                                    <li>If while purchasing any healthcare product or services, you upload the invoice
                                        of the same or use SellonFly voucher(s) or SellonFly to purchase the same,
                                        SellonFly would gain access to information on such invoice(s) which may include
                                        your sensitive personal health information.
                                    </li>
                                    <li>You hereby expressly consent to SellonFly gaining access and storing such
                                        information and processing the same in the manner set out in these terms.
                                    </li>
                                    <li>SellonFly shall have the rights in the above clause (b) unless you expressly
                                        withdraw the consent you have given us, by contacting us with such request at <a
                                            href="mailto:contact@SellonFly.com">contact@SellonFly.com</a></li>
                                </ol>
                            </li>
                            <li><b>Third Party Access to User Information</b>
                                <ol type="a">
                                    <li>We will share your information with third parties only in the ways that are
                                        described in this privacy statement.
                                    </li>
                                    <li>We may disclose User Provided and Automatically Collected Information unless
                                        consent to the same has been specifically withdrawn by the User.
                                    </li>
                                    <li>We will not share your contact information at any point without your prior
                                        content, but your selfies, comments and other actions specific to our system
                                        might be visible to other users and merchants on the platform. However, without
                                        your prior consent such users will not have access to your contact information.
                                        You, as a User, have the ability, to remove your information from the system and
                                        to restrict our access and use of your personal data;
                                    </li>
                                    <li>We shall provide all information that is to be provided when required by law,
                                        such as to comply with a summons, or similar legal process as well as when we
                                        believe in good faith that disclosure is necessary to protect our rights,
                                        protect your safety or the safety of others, investigate fraud, or respond to a
                                        government request;
                                    </li>
                                    <li>Our trusted services providers who work on our behalf, do not have an
                                        independent use of the information we disclose to them, and have agreed to
                                        adhere to the rules set forth in this privacy statement and apply data
                                        protection measures, at least equal to those set out below in the section “Data
                                        Security Measures”.
                                    </li>
                                    <li>If in the event that SellonFly is involved in a merger, acquisition, or sale of
                                        all or a portion of its assets, you will be notified via email and/or a
                                        prominent notice on our Web site of any change in ownership or uses of this
                                        information, as well as any choices you may have regarding this information.
                                    </li>
                                    <li>We also may share data that is not linkable to a particular consumer or device
                                        (for example, information that has been aggregated with other records) for
                                        general business purposes.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Data Security Measures</b>
                                <ol type="a">
                                    <li>SellonFly takes security of your data very seriously. We work hard to protect
                                        information you provide from loss, misuse, and unauthorised access or
                                        disclosure.
                                    </li>
                                    <li>We maintain commercially reasonable and standard measures to maintain
                                        information security and prevent it from unauthorized access. However, given the
                                        nature of communications and information processing technology, SellonFly
                                        cannot guarantee that any information, during transmission through the internet
                                        or while being processed, will be absolutely safe from intrusion by others.
                                    </li>
                                    <li>If SellonFly becomes aware of any breach of security of your information, We
                                        will notify you using the email address that We have. If you do not agree to the
                                        terms discussed above, you may exit SellonFly or stop using the same. When you
                                        access Our App, you acknowledge that you have read and agreed to abide by the
                                        terms described above.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Data Retention Policy</b>
                                <ol type="a">
                                    <li>We will retain User Provided data for as long as you use the Application and for
                                        a reasonable time thereafter.
                                    </li>
                                    <li>We will retain Automatically Collected information for up to 24 months and
                                        thereafter may store it in aggregate.
                                    </li>
                                    <li>If you would like us to delete User Provided Data that you have provided via the
                                        Application, please contact us at <a
                                            href="mailto:contact@SellonFly.com">contact@SellonFly.com</a> and we will
                                        respond in a reasonable time.
                                    </li>
                                    <li>The User must note that some or all the User Provided Data may be required in
                                        order for the Application to function properly.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Tracking Technologies</b>
                                <ol type="a">
                                    <li>We may use cookies, web beacons, tracking pixels, and other tracking
                                        technologies on the Application to help customize the Application and improve
                                        your experience. When you access the Application, your personal information is
                                        not collected using tracking technology. Most browsers are set to accept cookies
                                        by default. You can remove or reject cookies but be aware that such action could
                                        affect the availability and functionality of the Application.
                                    </li>
                                    <li>The Application may contain links to third-party websites and applications of
                                        interest, including advertisements and external services, that are not
                                        affiliated with us. Once you have used these links to leave the Application, any
                                        information you provide to these third parties is not covered by this Privacy
                                        Policy, and we cannot guarantee the safety and privacy of your information.
                                        Before visiting and providing any information to any third-party websites, you
                                        should inform yourself of the privacy policies and practices (if any) of the
                                        third party responsible for that website, and should take those steps necessary
                                        to, in your discretion, protect the privacy of your information. We are not
                                        responsible for the content or privacy and security practices and policies of
                                        any third parties, including other sites, services or applications that may be
                                        linked to or from the Application.
                                    </li>
                                </ol>
                            </li>
                            <li><b>Release of User Information: We release account and other personal information when
                                we believe release is appropriate to comply with applicable law; enforce or apply our
                                Conditions of Use and other agreements; or protect the rights, property or safety of
                                merchants, our users or others. This includes exchanging information with other
                                companies, organisations, government or regulatory authorities for fraud protection and
                                credit risk reduction. Obviously, however, this does not include selling, renting,
                                sharing or otherwise disclosing personally identifiable information from customers for
                                commercial purposes in a way that is contrary to the commitments made in this Privacy
                                Notice</b></li>
                            <li><b>Disclosure of Information</b>
                                <ol type="a">
                                    <li>to report or collect on payments owed to Sellers, merchants or other business
                                        partners;
                                    </li>
                                    <li>as necessary to perform contractual obligations towards you with merchant to the
                                        extent you have purchased or redeemed a voucher for goods or services offered by
                                        a merchant or participated in an offer, rewards, contest or other activity or
                                        program sponsored or offered through us or the Sellers on behalf of a merchant;
                                    </li>
                                    <li>in case of a merger, acquisition or reorganization with a purchaser of our
                                        company or all or substantially all of our assets;
                                    </li>
                                    <li>to comply with legal orders and government requests, or as needed to support
                                        auditing, compliance;
                                    </li>
                                    <li>to combat fraud or criminal activity, and to protect our rights or those of our
                                        affiliates, business partners and users, or as part of legal proceedings
                                        affecting SellonFly;
                                    </li>
                                    <li>in response to a legal process, including to law enforcement agencies,
                                        regulators, and courts; or
                                    </li>
                                    <li>with your consent or as otherwise required or permitted by law.</li>
                                </ol>
                            </li>
                            <li><b>User Opt-out: The User can choose to stop all collection of information by the
                                Application easily by uninstalling the Application. You may use the standard uninstall
                                processes as may be available as part of your mobile device or via the mobile
                                application marketplace or network. You can also request to opt-out via email, at <a
                                    href="mailto:contact@SellonFly.com">contact@SellonFly.com</a>.</b></li>
                            <li><b>SellonFly shall not engage in any use of the Application to knowingly solicit data
                                from or market to children under the age of 13. While it is our constant endeavour to
                                show minors only age-appropriate content through various internal checks, such as age
                                verification at the time of registration, directly or through Facebook, Community
                                Guidelines and others that are updated periodically, if a parent or guardian becomes
                                aware that his or her child has provided us with information without their consent, he
                                or she should contact us at <a
                                    href="mailto:contact@SellonFly.com">contact@SellonFly.com</a>. We will delete such
                                information from our files within a reasonable time.</b></li>
                            <li><b>You may at any time review or change the information in your account or terminate
                                your account. If your SellonFly user account is terminated or deleted, access to your
                                username, password, and all related information, files, and content associated with your
                                account shall be terminated. However, some information may be retained in our files to
                                prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms
                                of Use and/or comply with legal requirements.</b></li>
                            <li><b>By using or continuing to use SellonFly, you agree to our use of your information
                                (including your personal information and/or your sensitive personal data or information)
                                in accordance with this Privacy Policy, as may be amended from time to time by
                                SellonFly in its discretion. You also agree and consent to us collecting, storing,
                                processing, transferring and sharing information (including sensitive personal data or
                                information) related to you with third parties or service providers for the purposes as
                                set out in this Privacy Policy.</b></li>
                            <li><b>You may contact us at <a
                                href="mailto:contact@SellonFly.com">contact@SellonFly.com</a> if you wish to obtain:
                                (a) confirmation on whether your respective Personal Data has been processed or is being
                                processed; (b) brief summary of your respective Personal Data that has been processed or
                                is being processed; and (c) brief summary of the Terms. You may also contact us if you
                                wish any respective information to be corrected or removed or deleted.</b></li>
                        </ul>
                    </div>

                    <h3>Direct Payment Facility</h3>
                    <ul>
                        <li>The User may, if easier, opt to make direct payment of such purchase price to SellonFly for
                            the purchased goods or services (“Direct Payment Facility”). In the event that You make any
                            payment in the above-mentioned manner, SellonFly shall be deemed to be the agent of the
                            respective merchant authorised to collect such purchase price from You on behalf of the
                            merchant.
                        </li>
                        <li>Any and all communication by SellonFly or the merchant regarding the Direct Payment
                            Facility, whether to SellonFly users, your customers or clients, our respective vendors,
                            employees or agents, governmental authorities or any other person or entity whatsoever shall
                            clearly indicate that SellonFly is acting purely as an agent in relation to the Direct
                            Payment Facility
                        </li>
                    </ul>

                    <h3>Feedback</h3>
                    <p>You agree that any recommendation, idea, proposal, suggestion, feedback or other input
                        (“Feedback”) you submit to SellonFly related to its services, websites, apps, or technology may
                        be used by SellonFly without any notice, obligation, restriction, reimbursement or compensation
                        to you and you waive (or agree not to enforce) any and all rights that may now or in future
                        exist (including moral and equivalent rights) in any Feedback.</p>

                    <h3>SellonFly Loyalty Rewards Programme</h3>
                    <ul>
                        <li>To make the SellonFly experience more fun and fulfilling we have a discretionary unique
                            reward programme for our loyal users. When you purchase any product or service from any of
                            our participating merchants and you verify the transaction on the SellonFly app by
                            uploading your selfie clicked at the merchant outlet and the bill showing the date and the
                            amount, and take such other steps as many be instructed on the app, certain reward points
                            are added to your account.
                        </li>
                        <li>You may use the points in your account to purchase vouchers issued by SellonFly which you
                            may use at specified locations as indicated at the time you purchase the voucher. Presently
                            SellonFly sells vouchers which can be used at merchants such as McDonald's, Pizza Hut, Hard
                            Rock Cafe, BookMyShow, Flipkart, Myntra, etc.
                        </li>
                        <li>The reward programme is being offered at our discretion and we reserve the right to cancel
                            or discontinue the reward programme and/or change the terms of the programme, without any
                            liability whatsoever to you. You agree that you shall have no rights or claims of whatsoever
                            nature against us in such event.
                        </li>
                        <li>If you have any questions regarding the rewards programme, write to us at <a
                            href="mailto:contact@SellonFly.com">contact@SellonFly.com</a></li>
                    </ul>

                    <h3>About these Terms</h3>
                    <ul>
                        <li>Third Party Beneficiaries and Conflicts. These Terms control the relationship between
                            SellonFly and you. They do not create any third party beneficiary rights. If there is a
                            conflict or inconsistency between the terms in this document and the additional terms
                            associated with a particular Service, the additional terms will control solely for that
                            conflict or inconsistency.
                        </li>
                        <li>Modification of the Terms. We may modify the Terms from time to time to keep in compliance
                            with current law as well any future legislations that aim to protect information and
                            privacy. Unless we indicate otherwise, modifications will be effective as of the date they
                            are posted on this page or any successor page. You should look at the Terms regularly. We
                            will provide notice (in accordance with Section 3(c) above) of material modifications.
                        </li>
                        <li>Continued Use of the Services. You may stop using the Services at any time, but your
                            continued use of or subscription to a Service after the effective date of any modifications
                            to the Terms means that you agree to the Terms as modified as well as consent to
                            SellonFly's collection and processing of User data.
                        </li>
                        <li>Waiver and Severability of Terms. The failure of SellonFly to exercise or enforce any right
                            or provision of these Terms will not constitute a waiver of such right or provision. If any
                            provision (or part of a provision) of these Terms is found to be invalid, SellonFly and you
                            nevertheless agree to give effect to the intentions as reflected in the provision, and the
                            other provisions of these Terms remain in full force and effect.
                        </li>
                        <li>Assignment by SellonFly. SellonFly may freely assign these Terms and all of the policies
                            and other documents incorporated or referenced in it (including all rights, licenses, and
                            obligations under it or them), in whole or in part and without notice, for any reason,
                            including for the purpose of internal restructuring (for example, mergers or liquidations).
                        </li>
                    </ul>

                    <h3>Indemnification</h3>
                    <ul>
                        <li>You agree to defend, indemnify, and hold harmless SellonFly and all SellonFly Entities
                            from and against all claims and expenses, including, without limitation, attorneys' fees,
                            arising out of, related to, or in connection with any of the following: (a) any User Content
                            submitted or posted by you on the platform, or any use of the platform in violation of these
                            Terms of Use; (b) fraud you commit or your intentional misconduct or gross negligence; or
                            (c) your violation of any applicable laws or rights of a third-party.
                        </li>
                        <li>You are solely responsible for your interactions with merchants as well as other users on
                            the platform. To the extent permitted under applicable laws, you hereby release SellonFly
                            from any and all claims or liability related to any product or service of a merchant,
                            without limitation, but not limited to any harm caused to you by action or inaction of a
                            merchant, a merchant's failure to comply with applicable law and any conduct, speech or User
                            Content, whether online or offline, of any other third-party.
                        </li>
                    </ul>

                    <h3>Grievance Redressal and Dispute Resolution</h3>
                    <ul>
                        <li>SellonFly is not liable for any infringement of copyright arising out of materials posted
                            on or transmitted through the site, or items advertised on the site, by end users or any
                            other third parties. In the event you have any grievance in relation to any content uploaded
                            on the Site, you may contact at: <br />
                                <address>
                                    SellonFly <br />
                                    <a href="mailto:contact@SellonFly.com">contact@SellonFly.com</a> <br />
                                </address>
                        </li>
                        <li>If you believe that SellonFly has not adhered to this Privacy Policy (as set out under
                            clause 10) you may write to SellonFly at the following e-mail address: <a
                                href="mailto:contact@SellonFly.com">contact@SellonFly.com</a>. In your email, please
                            describe in as much detail as possible ways in which you believe the Privacy Policy has not
                            been complied with. We will investigate your complaint within reasonable period of time.
                        </li>
                        <li>We will do our best to resolve any disputes about these Terms of Use. If you wish to bring a
                            legal claim against us, these Terms, as well as all user activity on the SellonFly Platform
                            shall be governed by and construed in accordance with the laws of India.
                        </li>
                        <li>You agree that all claims, differences or disputes arising under or in connection with or in
                            relation hereto the SellonFly Platform, the Terms, relating to or in connection with these
                            Terms, or transactions entered into on or through SellonFly, or any content uploaded on the
                            SellonFly Platform or the relationship between User and SellonFly shall be subject to the
                            exclusive jurisdiction of the courts at Delhi and the User hereby accedes to and accepts the
                            jurisdiction of such courts.
                        </li>
                    </ul>

                    <h3>Cancellation and refund policy:</h3>
                    <ul>
                        <li>Cancellation due to reasons not attributable to SellonFly, that is, in the event you
                            provide incorrect phone numbers, delivery address, or that you were unresponsive while
                            either of our team or the rider team tried to reach out or, not reachable or unavailable
                            during the delivery of the services offered to you, shall be non-refundable in nature.
                        </li>
                        <li>There may be cases where SellonFly is either unable to accept your order or cancels the
                            order, due to reasons including without limitation, technical errors, unavailability of the
                            item(s) ordered, or any other reason attributable to SellonFly, Restaurant Partner/Store or
                            Delivery Partner. In such cases, SellonFly shall not charge a cancellation charge from you.
                            If the order is canceled after payment has been charged, you are eligible for a refund of
                            the order value or any part thereof, the said amount will be reversed to you.
                        </li>
                        <li>No replacement / refund / or any other resolution will be provided without Restaurant
                            Partner's/Store(s)' permission. We will need to verify the details from the store before we
                            proceed with any refund.
                        </li>
                        <li>Any complaint, with respect to the order which shall include instances but not be limited to
                            food spillage, foreign objects in food, delivery of the wrong order or food and beverages or
                            Products, poor quality, You will be required to share the proof of the same before any
                            resolution can be provided. In the absence of the proof, SellonFly will not consider the
                            request as valid request and refund or replacement will not be provided in such cases.
                        </li>
                        <li>You shall not be entitled to a refund in case instructions placed along with the order are
                            not followed in the form and manner You had intended. Instructions are followed by the
                            Restaurant Partner /Store on a best-efforts basis.
                        </li>
                        <li>All refunds shall be processed in the same manner as they are received, unless refunds have
                            been provided to You in the form of credits, refund amount will reflect in your account
                            based on respective banks policies.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </>;
}

export default TermsOfUse;
import React, {useEffect, useState} from "react";
import ListService from "../../services/ListService";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import TitleBanner from "../../components/TitleBanner";

const AllStores = () => {
    const [listService] = useState(new ListService());
    const commonState = useSelector((state: any) => state.common);
    const locationState = useSelector((state: any) => state.location);
    const [stores, setStores] = useState([]);

    const getStores = async () => {
        if (commonState.locations.length > 0 && locationState.city) {
            const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
            const allStores = await listService.stores(location._id, {limit: 20, coordinates: locationState.coordinates});
            setStores(allStores);
        }
    }

    useEffect(() => {
        getStores();
    }, [commonState, locationState]);

    return (
        <>
            <TitleBanner title={'All Stores'}/>
            <section className="top_brands_sec pt80">
                <div className="container">
                    <div className="brand_grid">
                        {stores.map((content) => <div className="brands_block">
                            <Link to={`/store/${content['_id']}`}>
                                <img src={content['logo'] != null ? content['logo'] : '/assets/images/logo.png'} alt=""
                                     srcSet="" style={{width: '100px', height: '100px', objectFit: 'cover'}}/>
                                <span><small>{content['name']}</small></span>
                            </Link>
                        </div>)}
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllStores;
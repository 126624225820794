import TitleBanner from "../../components/TitleBanner";
import {Helmet} from "react-helmet";
import React from "react";

function Faq() {
    return <>
        <Helmet>
            <title>SellOnFly, FAQ, frequently asked questions, platform, services, policies, shopping experience</title>
            <meta name="title"
                  content={`SellOnFly, FAQ, frequently asked questions, platform, services, policies, shopping experience`}/>
            <meta name="description"
                  content="Find answers to frequently asked questions about SellOnFly's platform, services, and policies. Get insights to enhance your shopping experience."/>
        </Helmet>
        <TitleBanner title="FAQ's" />
        <section className="">
            <div className="container">
                <div className="max700 mx-auto pt60">
                    <div className="accordion wow fadeInUp" id="bb_faqs">

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a1" aria-expanded="false">
                                        What does SellOnFly do?
                                    </button>
                                </h2>
                                <div id="faqs_a1" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs" style={{}}>
                                    <div className="accordion-body">
                                        <p>SellOnFly is an innovative hyperlocal online platform, dedicated to
                                            transforming offline marketing and revolutionizing local shopping
                                            experiences in India. SellOnFly enables users to effortlessly explore
                                            nearby retailers and unlock exclusive discounts and deals. This innovative
                                            platform empowers retail merchants to effectively connect with their
                                            customers and offer personalized incentives.</p>
                                        <p>Their state-of-the-art discovery and savings platform are designed to empower
                                            users with advanced features, providing unprecedented convenience and
                                            opportunities for discovering local retailers and accessing exclusive
                                            deals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a2">
                                        What kind of offers SellOnFly has?
                                    </button>
                                </h2>
                                <div id="faqs_a2" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>SellOnFly has various kinds of offers like coupons, vouchers, cashbacks,
                                            discounts , deals etc. which gives you best deals at all times.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a3">
                                        How can SellOnFly benefit my local business?
                                    </button>
                                </h2>
                                <div id="faqs_a3" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>SellOnFly helps you go online and gives access to countless exclusive deals
                                            and discounts, to attract and retain new customers and stay ahead of the
                                            competition in the market.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a4">
                                        How much commission does SellOnFly take?
                                    </button>
                                </h2>
                                <div id="faqs_a4" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>0% commission process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a5">
                                        How do I avail the coupons at local stores or online?
                                    </button>
                                </h2>
                                <div id="faqs_a5" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>The points issued shall be valid for a period of 30 days from issuance, which
                                            may however be extended at the discretion of the Company based on factors
                                            including history of user activity in the last 90 days for points
                                            generation, redemption etc. This policy is subject to periodic review.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a6">
                                        How do I list my business with SellOnFly?
                                    </button>
                                </h2>
                                <div id="faqs_a6" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>If you have a business that you want to list on SellOnFly to boost your
                                            sales and engagement, contact us <a
                                                href="mailto:contact@SellOnFly.com">contact@SellonFly.com</a> or check
                                            our partner page <a href="partner.html">www.sellonfly.com/partner</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a7">
                                        How do I contact SellOnFly?
                                    </button>
                                </h2>
                                <div id="faqs_a7" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>Check our partner page <a
                                            href="partner.html">www.sellonfly.com/partner</a> or Contact us <a
                                            href="mailto:contact@SellOnFly.com">contact@SellonFly.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a8">
                                        What kind of categories SellOnFly features?
                                    </button>
                                </h2>
                                <div id="faqs_a8" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>You can find best deals on Beauty, health, food and drinks, nightlife, travel
                                            deals etc. on SellOnFly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a9">
                                        Do SellOnFly has an app?
                                    </button>
                                </h2>
                                <div id="faqs_a9" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>Yes! Download SellOnFly app from playstore - SellOnFly link to save money
                                            every time you make a purchase. Get best deals on shopping in your
                                            locality.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a10">
                                        What are the modes of payment for SellOnFly?
                                    </button>
                                </h2>
                                <div id="faqs_a10" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>SellOnFly accepts all forms of Online payments such as net banking, UPI,
                                            Credit cards, debit cards, bank transfers etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a11">
                                        Do I need to use the coupon on same day that I buy it?
                                    </button>
                                </h2>
                                <div id="faqs_a11" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>No. Each coupon has its own expiry date. Please check the validity period on
                                            your SellOnFly coupon for more details.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item faqs_a">
                            <div className="acc_inner">
                                <h2 className="accordion-header faqs_a_head" id="faq_a">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faqs_a12">
                                        Can I buy SellOnFly coupon as gift for someone else?
                                    </button>
                                </h2>
                                <div id="faqs_a12" className="accordion-collapse collapse" aria-labelledby="faq_a"
                                     data-bs-parent="#bb_faqs">
                                    <div className="accordion-body">
                                        <p>Absolutely Yes. SellOnFly coupons are the perfect gifting option for a loved
                                            one.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </>;
}

export default Faq;
import {Link, useLocation, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Slider from "react-slick";
import GalleryType3 from "../../components/GalleryType3";
import {Modal} from "react-bootstrap";
import ListService from "../../services/ListService";
import GalleyType2 from "../../components/GalleyType2";
import ItemStandard from "../../components/ItemStandard";
import {useNavigate} from "react-router";

const Category = () => {
	const navigate = useNavigate();
	const {slug} = useParams();
	const location = useLocation();
	const [listService] = useState(new ListService());
	const commonState = useSelector((state: any) => state.common);
	const locationState = useSelector((state: any) => state.location);
	const [subCategories, setSubCategories] = useState([]);
	const [subCategoryExtendable, setSubCategoryExtendable] = useState(false);
	const [showSubCategoryModel, setShowSubCategoryModel] = useState(false);
	const [brands, setBrands] = useState([]);
	const [offers, setOffers] = useState<any[]>([]);
	const [categoryDetails, setCategoryDetails] = useState<null|any>(null);

	const galleryType2Settings = {
		autoplay: false,
		autoplaySpeed: 2000,
		speed: 800,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				},
			},
			{
				breakpoint: 575,
				settings: {
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1
				},
			}]
	};

	const [sections, setSections] = useState([]);

	useEffect(() => {
		if (commonState.homeContent.length > 0) {
			setSections(commonState.categoryContent);
		} else if (localStorage.getItem('categoryContent')) {
			setSections(JSON.parse(localStorage['categoryContent']));
		}
		const _categories = commonState.categories.filter((cat: any) => cat.parent && cat.parent.slug === slug);
		if (_categories.length > 4) {
			setSubCategoryExtendable(true);
		}
		setSubCategories(_categories);

		const category = commonState.categories.find((cat: any) => cat.slug == slug);
		setCategoryDetails(category);
	}, [commonState, slug]);

	const getBrands = async () => {
		const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
		const category = commonState.categories.find((cat: any) => cat.slug == slug);
		const stores = await listService.stores(location._id, {page: 1, limit: 20, categories: [category._id], type: 'Brand'});
		if (brands.length === 0) {
			setBrands(stores);
		}
	};

	const shuffleArray = (array: any[]) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}

	const getOffers = async () => {
		const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
		const category = commonState.categories.find((cat: any) => cat.slug == slug);
		const deals = await listService.deals(location._id, {page: 1, limit: 5, categories: [category._id], superSaver: false});
		const coupons = await listService.coupons(location._id, {page: 1, limit: 5, categories: [category._id], superSaver: false});
		const offers = shuffleArray([...deals, ...coupons]);
		setOffers(offers);
	};

	useEffect(() => {
		if (commonState.locations.length > 0) {
			getBrands();
			getOffers();
		}
	}, [commonState.locations, slug]);

	return <>
		{/* <section className='banner_section category_banner'></section> */}

		{/* Sub Category List Section*/}
		{/* {subCategories.length > 0 && <section className='category_sec'>
			<div className='container'>
				<div className='home_categories'>
					{subCategories.slice(0, 5).map((cat: any) => <div className='category_block'>
						<Link to={`${location.pathname}/${cat.slug}`}>
							<img src={cat.image} alt='' srcSet=''/>
							<h5>{cat.title}</h5>
						</Link>
					</div>)}
					{subCategoryExtendable && <div className='see_all_btn text-center'>
						<a onClick={() => setShowSubCategoryModel(true)} style={{cursor: 'pointer'}}>
							<img src='/assets/images/store.png' alt='' srcSet=''/>
							<h5>See All Sub Categories</h5>
							<img src='/assets/images/angle-double-right.png' alt='' srcSet=''
								 className='d-lg-inline-block d-none'/>
						</a>
					</div>}
				</div>
			</div>
		</section>} */}

		{/* Category Slider Section */}
		{sections.map((section) => <>
			{section['section']['type'] === 'Gallery Type 3' && section['section']['pageId'] === slug  && <section className="sponsered_banner_sec pt80">
				<Slider {...galleryType2Settings} className='sponsered_ban_slider side12'>
					{(section['contents'] as any[]).map((content) => <GalleryType3 content={content}/>)}
				</Slider>
			</section>}
		</>)}

		{brands.length > 0 && <section className="top_brands_sec pt80">
			<div className="container">
				<div className="site_headline row align-items-center">
					<div className="col-md-12 bb_time_title">
						<h2>Top {categoryDetails != null ? categoryDetails.title : ''} Brands</h2>
					</div>
				</div>
				<div className="brand_grid">
					{ brands.map((store: any) => <div className="brands_block">
						<a href="javascript:void(0);">
							<img src={store.logo} alt="" srcSet=""/>
							{store.savings > 0 ? <span>Save {store.savings}%</span> : <></>}
						</a>
					</div>)}
				</div>
			</div>
		</section>}

		{
			offers.length > 0 && <section className="category_offers pt80">
				<div className="container">
					<div className="site_headline row align-items-center">
						<div className="col-md-12 bb_time_title">
							<h2>{categoryDetails != null ? categoryDetails.title : ''} Offers</h2>
						</div>
					</div>
					<div className="deals_grid">
						{ offers.map((item) => <ItemStandard  item={item}/>)}
					</div>
					<div className="text-center bottom_viewmore mt-4">
						<a href="javascript:void(0)" className="view_more_link" onClick={() => {
							navigate(`/view-all/standard/category/${categoryDetails._id}`);
						}}>View More<i className="ps-1 fa-solid fa-angle-down"></i></a>
					</div>
				</div>
			</section>
		}

		{/* Category Modal */}
		<Modal className="full_width_popup modal fade" show={showSubCategoryModel} id="all_subcategories"  role="dialog"
			 aria-labelledby="all_subcategoriesLabel" aria-hidden="true">
			<button type="button" className="btn-close" onClick={() => setShowSubCategoryModel(false)}></button>
			<Modal.Body className="modal-dialog modal-fullscreen bg-white" role="document">
				<div className="modal-content modal_content">
					{/* <div className="modal-body"> */}
						<div className="container">
							<div className="site_headline">
								<h2>All Sub-categories</h2>
							</div>
							<div className="all_categories">
								{subCategories.map((cat: any) => <div className='category_block'>
									<Link to={`${location.pathname}/${cat.slug}`} onClick={() => setShowSubCategoryModel(false)}>
										<img src={cat.image} alt='' srcSet=''/>
										<h5>{cat.title}</h5>
									</Link>
								</div>)}
							</div>
						</div>
					{/* </div> */}
				</div>
			</Modal.Body>
		</Modal>
	</>;
};

export default Category;

import {Modal} from "react-bootstrap";
import '../../App.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useState} from "react";


const CouponRedeemModal = ({item, show, handleClose}: {item: any, show: boolean, handleClose: VoidFunction}) => {
    const [codeCopied, setCodeCopied] = useState(false);

    return <Modal className="coupon_codemodal modal fade p-0 m-0" show={show} id="coupon_codemodal"
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
        <Modal.Dialog className="modal-dialog modal-lg modal-dialog-centered p-0 m-0">
            <Modal.Body className="modal-content modal_content m-0 p-0">
                <button type="button" className="btn-close" onClick={() => handleClose()}></button>
                <div className="modal-body">
                    <div className="coupon_code_msz text-center">
                        <div className="hurray_img"><img src="/assets/images/yay.png" alt="" srcSet="" /></div>
                        {item.code && <>
                            <CopyToClipboard text={item.code} onCopy={() => {
                                setCodeCopied(true);
                            }}>
                                <div className="couponcode"><span>{item.code}</span><img src="/assets/images/coupon_design.png" alt=""
                                                                                         srcSet="" /></div>
                            </CopyToClipboard>
                            {codeCopied && <p className="copy_msz">Code Successfully Copied</p>}
                            {item.link && <p className="last_action"><a href={item.link}>CLick Here</a> to visit the store site and use the
                                coupon at the time of checkout.</p>}
                        </>}
                        {!item.code && <>
                            {item.link && <p className="last_action"><a href={item.link}>CLick Here</a> to visit the store site to use the offer.</p>}
                        </>}
                    </div>
                    <img src="/assets/images/girfts.png" className="gift_img" alt="" srcSet="" />
                </div>
            </Modal.Body>
        </Modal.Dialog>
    </Modal>;
}

export default CouponRedeemModal;
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ListService from "../../services/ListService";
import {useSelector} from "react-redux";
import ItemStandard from "../../components/ItemStandard";

const AllStandardOffer = ({filter, id}: {filter: string, id: string}) => {
    const [listService] = useState(new ListService());
    const commonState = useSelector((state: any) => state.common);
    const locationState = useSelector((state: any) => state.location);
    const [offers, setOffers] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [categoryDetails, setCategoryDetails] = useState<null|any>(null);

    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const getCategoryOffers = async () => {
        const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
        const category = commonState.categories.find((cat: any) => cat._id == id);
        setCategoryDetails(category);
        const deals = await listService.deals(location._id, {page: page, limit: 20, categories: [category._id], superSaver: false});
        const coupons = await listService.coupons(location._id, {page: page, limit: 20, categories: [category._id], superSaver: false});
        const offers = shuffleArray([...deals, ...coupons]);
        setOffers(offers);
    };

    const getStoreOffers = async () => {
        const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
        const deals = await listService.deals(location._id, {page: page, limit: 20, store: id, superSaver: false});
        const coupons = await listService.coupons(location._id, {page: page, limit: 20, store: id, superSaver: false});
        const offers = shuffleArray([...deals, ...coupons]);
        setOffers(offers);
    };

    useEffect(() => {
        const handleScroll = () => {
            const isBottom =
                window.innerHeight + window.pageYOffset >= document.body.offsetHeight;

            if (isBottom) {
                setPage(page + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (commonState.locations.length > 0) {
           if (filter === 'category') {
               getCategoryOffers();
           } else if (filter === 'store') {
               getStoreOffers();
           }
        }
    }, [commonState.locations, id, filter, page]);

    return <>
        {
            offers.length > 0 && <section className="category_offers pt80 pb80">
                <div className="container">
                    <div className="site_headline row align-items-center">
                        <div className="col-md-12 bb_time_title">
                            <h2>{categoryDetails != null ? categoryDetails.title : ''} Offers</h2>
                        </div>
                    </div>
                    <div className="deals_grid">
                        { offers.map((item) => <ItemStandard  item={item}/>)}
                    </div>
                </div>
            </section>
        }
    </>;
}

export default AllStandardOffer;
import Api from "../../services/Api";
import BBEndpoints from "../../core/config/Endpoints";

export default class VoucherService {
    async claim(id: string) {
        const token = localStorage.getItem("token") || '';
        if (!token) {
            return;
        }
        return await Api.post(
            `${BBEndpoints.voucher}/${id}/claim`, {}, {
                "Content-Type": "application/json",
                "Authorization": token,
            });
    }
}

import {useEffect, useState} from "react";
import ListService from "../../services/ListService";
import {useSelector} from "react-redux";
import SuperSaverVouchers from "../../modules/Vouchers/SuperSaverVouchers";

const SuperSaverPartners = () => {
    const commonState = useSelector((state: any) => state.common);
    const locationState = useSelector((state: any) => state.location);
    const [listService] = useState(new ListService());
    const [stores, setStores] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [activeStore, setActiveStore] = useState(null);

    const getStores = async () => {
        if (commonState.locations.length > 0 && locationState.city) {
            const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
            const allStores = await listService.stores(location._id, {limit: 100, coordinates: locationState.coordinates, superSaver: true});
            setStores(allStores);
        }
    };

    useEffect(() => {
        getStores();
    }, [commonState, locationState]);


    return (
        <>
            <section className="banner_section inner_bg">
                <div className="container">
                    <h1 className="page_title text-center m-0 mx-auto text-white">Vouchers</h1>
                </div>
            </section>

            <section className="pricing_tabs max1024 mx-auto pt60 pb60">
                <div className="container">
                    <div className="voucher_row">
                        {stores.map((store: any) => <div className="voucher_block" onClick={() => {
                            setShow(true);
                            setActiveStore(store);
                        }}>
                            <a href="javascript:void(0)">
                                <img src={store['logo'] != null ?  store['logo'] : '/assets/images/logo.png'} alt="" srcSet=""/>
                                <h4>{store.name}</h4>
                            </a>
                        </div>)}
                    </div>
                </div>
            </section>
            <SuperSaverVouchers store={activeStore} show={show} handleClose={() => {
                setShow(false);
                setActiveStore(null);
            }} />
        </>
    );
}

export default SuperSaverPartners;

import Api from "../../services/Api";
import BBConstants from "../../core/config/Constants";
import BBEndpoints from "../../core/config/Endpoints";

export default class ContactService {
    async contact({firstName, email, phoneNumber, message, lastName}: {firstName: string, email: string, phoneNumber: string, message: string, lastName?: string}) {
        console.log(firstName);
        await Api.post(BBEndpoints.contact, {
            firstName,
            lastName,
            email,
            phoneNumber,
            message
        })
    }
}
import {Modal} from "react-bootstrap";
import moment from "moment";
import {Link} from "react-router-dom";

const DownloadAppModal = ({show, handleClose}: { show: boolean, handleClose: VoidFunction }) => {
    return <Modal className="modal fade" id="store_tandc_modal p-0 m-0" role="dialog"
                  show={show} size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
        <Modal.Body className="download-app bg-white" role="document">
            <div className="download-card">
                <div className="site_logo mb-5" style={{width: "200px"}}>
                    <Link to={'/'}><img src="/assets/images/logo.png" alt="" srcSet=""/></Link>
                </div>
                <div>
                    <div className="z-h1 text-center">Get the SellOnFly App</div>
                    <div className="z-p text-center">
                        Download the SellOnFly app now and get the best deals and offers on the go!
                    </div>
                    <div className="download-options d-flex justify-content-center w-100">
                        <div className="me-3">
                            <a href="https://play.google.com/store/apps/details?id=com.sellonfly" target="_blank">
                                <img src="/assets/images/Play Store.png" alt=""/>
                            </a>
                        </div>
                        <div className="ms-3">
                            <a href="https://apps.apple.com/in/app/sellonfly/id6471555505" target="_blank">
                                <img src="/assets/images/App Store.png" alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
    </Modal>;
}

export default DownloadAppModal;
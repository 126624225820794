import Api from "./Api";
import BBEndpoints from "../core/config/Endpoints";

export default class ContentService {
    async getContent(page: string, location: string): Promise<any> {
        const contents = await Api.get(BBEndpoints.customSections, {}, {
            page: page,
            location: location,
        });
        return contents;
    }
}
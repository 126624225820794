import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const ItemCustom = ({content}: {content: any}) => {
    const [redirectUrl, setRedirectUrl] = useState('');

    useEffect(() => {
        if (content['type'] === 'Deal') {
            setRedirectUrl(`/deal/${content['item']}`)
        } else  if (content['type'] === 'Coupon') {
            setRedirectUrl(`/coupon/${content['item']}`)
        } else if (content['type'] === 'Store') {
            setRedirectUrl(`/store/${content['item']}`)
        }
    }, [content]);


    return <div className="dealslv_slide">
        <Link to={redirectUrl}>
            <img src={content.image} alt="" srcSet=""/>
            <div className="dealslv_details">
                <h5>{content.title}</h5>
                <p>{content.description}</p>
            </div>
        </Link>
    </div>;
}

export default ItemCustom;
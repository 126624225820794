import Api from "../../services/Api";
import BBEndpoints from "../../core/config/Endpoints";

export default class SearchHistoryService {
    async addSearchHistory(title: string, location: string): Promise<any> {
        const token = localStorage.getItem("token") || '';
        console.log('TOKEN');
        console.log(token);
        if (token !== '') {
            await this.addUserSearchHistory(title, location);
        } else {
            return await Api.post(BBEndpoints.addSearchHistory, {}, {
                "Content-Type": "application/json",
            }, {
                title: title,
                location: location,
            });
        }

    }

    async addUserSearchHistory(title: string, location: string): Promise<any> {
        const token = localStorage.getItem("token") || '';
        if (token !== '') {
            return await Api.post(BBEndpoints.addUserSearchHistory, {}, {
                "Content-Type": "application/json",
                ...(token && {"Authorization": token}),
            }, {
                title: title,
                location: location,
            });
        }
    }

    async getTrendingSearches(location: string): Promise<string[]> {
        return await Api.get(BBEndpoints.trendingSearches, {
            "Content-Type": "application/json",
        }, {
            durationInHours: 24,
            location: location,
        });
    }

    async getRecentSearches(): Promise<any> {
        const token = localStorage.getItem("token") || '';
        if (token !== '') {
            return await Api.get(BBEndpoints.recentSearches, {
                "Content-Type": "application/json",
                ...(token && {"Authorization": token}),
            });
        }
    }
}
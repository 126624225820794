import TitleBanner from "../../components/TitleBanner";
import React, {useEffect, useState} from "react";
import ListService from "../../services/ListService";
import {useSelector} from "react-redux";
import GoldDeal from "../../components/GoldDeal";
import MembershipService from "./MembershipService";
import useRazorpay from "react-razorpay";
import {toast} from "react-hot-toast";
import BBConstants from "../../core/config/Constants";
import moment from "moment/moment";
import {Helmet} from "react-helmet";

const GoldMembership = () => {
    const commonState = useSelector((state: any) => state.common);
    const locationState = useSelector((state: any) => state.location);
    const userState = useSelector((state: any) => state.auth);
    const [listService] = useState(new ListService());
    const [membershipService] = useState(new MembershipService());
    const [offers, setOffers] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [isMember, setIsMember] = useState(null);
    const [cardDetails, setCardDetails] = useState<any>(null);
    const razorpay = useRazorpay();

    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const getDeals = async () => {
        const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
        const deals = await listService.deals(location._id, {page: page, limit: 100, superSaver: true});
        const offers = shuffleArray(deals);
        setOffers(offers);
    };

    const checkMembership = async () => {
        const member = await membershipService.isGoldMember();
        setIsMember(member);
        if (member) {
            const card = await membershipService.cardDetails();
            setCardDetails(card);
        }
    }

    const buyMembership = async () => {
        if (!userState.token) {
            toast.error('Please login to buy deal/coupon');
            return;
        }
        const orderId = await membershipService.createRazorpayOrder();
        const options = {
            'key': BBConstants.razorpayKey,
            'order_id': orderId,
            'name': 'SellOnFly',
            'description': 'Gold Membership Purchase',
            'prefill': {
                'contact': userState.phoneNumber ?? ''
            },
            handler: async function (response: any) {
                const res = await membershipService.verifyOrder({
                    'razorpay_order_id': response.razorpay_order_id,
                    'razorpay_payment_id': response.razorpay_payment_id,
                    'razorpay_signature': response.razorpay_signature,
                });
                if (res) {
                    toast.success('Gold membership purchased successfully');
                } else {
                    toast.error('Something went wrong. Please try again or contact support');
                }
            },
        };
        // @ts-ignore
        const rzp1 = new razorpay(options);

        rzp1.on("payment.failed", function (response: any) {
            toast.error(response.error.description);
        });

        rzp1.open();
    }


    useEffect(() => {
        if (commonState.locations.length > 0) {
            getDeals();
        }
        checkMembership();
    }, [commonState.locations]);


    return <>
        <Helmet>
            <title>SellOnFly: Exclusive Discounts with Gold Membership Card</title>
            <meta name="title"
                  content={`SellOnFly: Exclusive Discounts with Gold Membership Card`}/>
            <meta name="description"
                  content="Unlock exclusive discounts with Gold Membership Card at SellOnFly. Special offers for users on diverse purchases."/>
        </Helmet>
        <TitleBanner title={'Gold Membership'}/>
        <section className="gold_deals_sec pt80 pb80">
            <div className="container">
                {isMember !== null && !isMember &&
                    <div className="gold_card mx-auto text-center"><img src="/assets/images/gold-card.png" alt=""
                                                                        srcSet=""/>
                    </div>}
                {isMember !== null && cardDetails && <div className="gold_card mx-auto text-center">
                    <div className="text-end pb-2 pe-2">
                        {/*<a href="javascript:void(0);" className="view_more_link" data-bs-toggle="modal"*/}
                        {/*   data-bs-target="#store_tandc_modal">T&amp;C</a>*/}
                    </div>
                    <div className="card_img_details position-relative">
                        <img src="/assets/images/ssc-image-blue.jpg" alt="" srcSet=""/>
                        <div className="mem_card_detail">
                            <div className="mem_card_top row align-items-end">
                                <div className="card_no_16 col-9 text-start">{cardDetails['cardNumber']}</div>

                                <div className="cardvalid_upto col-3 text-end"><span className="valid_text d-block">valid upto</span><span
                                    className="valid_month">{moment(cardDetails['expiryDate']).format('MM')}</span>/<span
                                    className="valid_month">{moment(cardDetails['expiryDate']).format('YY')}</span>
                                </div>
                            </div>
                            <div className="mem_card_right">
                                {/* <img src="/assets/images/bb-logo.png" style={{objectFit: 'contain'}} alt="" srcSet="" /> */}
                                <h3 className="holder_name text-start mb-4">{userState.name}</h3>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='d-flex justify-content-center'>
                    {isMember !== null && !isMember &&
                        <button className={'btn btn-primary mt-3'} onClick={() => buyMembership()}>Purchase
                            card</button>}
                </div>
                <div className="gold_deals_grid mt-xl-5 mt-4">
                    {offers.map((offer, key) => <GoldDeal deal={offer} key={key}/>)}
                </div>
            </div>
        </section>
    </>;
}

export default GoldMembership;
import { createSlice } from '@reduxjs/toolkit';
import toast from "react-hot-toast";


const initialState = { state: 'Rajasthan', city: 'Kota', locality: '', coordinates: '', address: 'Kota, Rajasthan, India'  }

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        loadCurrentLocation(state, action) {
            state.state = localStorage.getItem('state') ?? 'Rajasthan';
            state.city = localStorage.getItem('city') ?? 'Kota';
            state.locality = localStorage.getItem('locality') ?? '';
            state.coordinates = localStorage.getItem('coordinates') ?? '';
            state.address = localStorage.getItem('address') ?? 'Kota, Rajasthan, India';
        },
        locationUpdated(state, action) {
            if (action.payload.state) state.state = action.payload.state;
            if (action.payload.city) state.city = action.payload.city;
            if (action.payload.locality) state.locality = action.payload.locality;
            if (action.payload.coordinates) state.coordinates = action.payload.coordinates;
            if (action.payload.address) state.address = action.payload.address;

            if (action.payload.state) localStorage.setItem('state', action.payload.state);
            if (action.payload.city) localStorage.setItem('city', action.payload.city);
            if (action.payload.locality) localStorage.setItem('locality', action.payload.locality ?? '');
            if (action.payload.coordinates) localStorage.setItem('coordinates', action.payload.coordinates ?? '');
            if (action.payload.address) localStorage.setItem('address', action.payload.address);
        },
    },
})

export const { locationUpdated, loadCurrentLocation } = locationSlice.actions
export default locationSlice.reducer

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import StoreService from "./Service";
import moment from "moment";
import CouponRedeemModal from "../../components/modals/CouponRedeemModal";
import OrderService from "../../services/OrderService";
import QRCode from "react-qr-code";

const Coupon = () => {
    const {id} = useParams();
    const [storeService] = useState(new StoreService());
    const [orderService] = useState(new OrderService());
    const [coupon, setCoupon] = useState(null);
    const [showRedeemDetails, setShowRedeemDetails] = useState(false);

    const getCoupon = async () => {
        const details = await storeService.couponDetails(id ?? '');
        setCoupon(details);
    }

    useEffect(() => {
        getCoupon();
    }, [id]);


    return <>
        {coupon && <section className="pt40 pb40">
            <div className="container">
                <div className="max700 mx-auto">
                    <div className="deal_details_top">
                        <div className="qr_code text-center">
                            <QRCode
                                style={{ height: "100", width: "100", backgroundColor: "transparent" }}
                                value={coupon['code']}
                            />
                        </div>
                        <div className="deal_details_top_one text-center">
                            <h5 className="v_c_d">Coupon code</h5>
                            <p className="deal_code">{coupon['code']}</p>
                        </div>
                        <div className="feel_free_to_call d-flex align-items-center justify-content-between flex-wrap">
                            <h5 className="text-center">Feel free to call for any query</h5>
                            <p className="text-center"><a href="tel:7820991614">
                                <img src="/assets/images/call.png" alt="" srcSet="" />
                                <span>+91-7820991614</span>
                            </a></p>
                        </div>
                        <div className="purchase_date_price d-flex align-items-center justify-content-between flex-wrap">
                            <div className="purchase_on text-center">
                                <h5>Valid Till</h5>
                                <p>{moment(coupon['expiryDate']).format('MMM DD, YYYY')}</p>
                            </div>
                            <div className="purchase_price text-center">
                                <span>₹0</span>
                            </div>
                        </div>
                    </div>
                    <div className="deal_name_detail">
                        <h5>{coupon['title']}</h5>
                        <p className="text-capitalize">Valid @ {coupon['store']['name']} Only</p>
                        <p><i className="fa-solid fa-calendar-days pe-2"></i>Redeemable for <strong>all days.</strong>
                        </p>
                        {coupon['expiryDate'] &&
                            <p><i className="fa-solid fa-clock pe-2"></i><strong>Expires</strong> on <strong>
                                {moment(coupon['expiryDate']).format('DD MMM YYYY')}
                            </strong></p>}
                    </div>
                    <div className="tnc_details_tab blog_tabs px-0">
                        <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tnc-tab" data-bs-toggle="tab" href="#tnc" role="tab"
                                   aria-controls="tnc" aria-selected="true">Terms & Conditions</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="orderdetails-tab" data-bs-toggle="tab" href="#orderdetails"
                                   role="tab" aria-controls="orderdetails" aria-selected="false">Details</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="howredeemdetails-tab" data-bs-toggle="tab"
                                   href="#howredeemdetails" role="tab" aria-controls="howredeemdetails"
                                   aria-selected="false">How to redeem</a>
                            </li>
                        </ul>
                        <div className="tab-content content_text" id="myTabContent">
                            <div className="tab-pane fade" id="tnc" role="tabpanel" aria-labelledby="tnc-tab">
                                <h4>Terms & Conditions</h4>
                                <ul>
                                    <li>Offers valid for all users.</li>
                                    <li>The images are for representation purpose only.</li>
                                    <li>These offers, vouchers, coupons, membership are valid for people aged 18 years and
                                        above.
                                    </li>
                                    <li>Vouchers can be redeemed only once.</li>
                                    <li>Vouchers will not be replaced if lost or stolen.</li>
                                    <li>Vouchers cannot be clubbed with in-store discounts & discounts from other platforms.
                                    </li>
                                    <li>Discount may not be applicable on combos, other special items.</li>
                                    <li>Discount may not be applicable on MRP Items.</li>
                                    <li>The store may levy Government taxes & service charge as per its discretion.</li>
                                    <li>It is advisable to make prior reservations by calling before visiting the store.</li>
                                </ul>
                            </div>
                            <div className="tab-pane fade" id="orderdetails" role="tabpanel"
                                 aria-labelledby="orderdetails-tab">
                                <h4>Details</h4>
                                {coupon['details']}
                            </div>
                            <div className="tab-pane fade" id="howredeemdetails" role="tabpanel"
                                 aria-labelledby="howredeemdetails-tab">
                                <h4>How to redeem</h4>
                                <ul>
                                    <li>Book your Bohni Deal by either paying the transaction fee or making a full payment.</li>
                                    <li>After completing the full payment, review the invoice located in your Profile under the
                                        Orders section.
                                    </li>
                                    <li>Show the invoice received in your profile to the vendor to avail the eligible deal.</li>
                                    <li>To avail the membership card deal, show your digital card from your profile, including
                                        QR code provided, to redeem deals on offline stores.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <button className='btn text-white w-100 mt-3' style={{backgroundColor: "#5F40A6", height: "50px"}} onClick={async () => {
                                await orderService.generateCoupon(coupon['_id']);
                                setShowRedeemDetails(true);
                            }}>Activate Coupon</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>}
        {coupon &&
            <CouponRedeemModal item={coupon} show={showRedeemDetails} handleClose={() => setShowRedeemDetails(false)} />}
    </>;
}

export default Coupon;
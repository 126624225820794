import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Login from "../modules/Authentication/Login";
import store from "../core/store";
import {logoutSuccess} from "../core/store/auth/reducers";
import CategoryModel from "./modals/CategoryModel";
import Search from "../modules/Search/Search";

function Header() {
    const locationState = useSelector((state: any) => state.location);
    const userState = useSelector((state: any) => state.auth);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showCategoryPopup, setShowCategoryPopup] = useState(false);
    const [showSearchPopup, setShowSearchPopup] = useState(false);
    const [homeLocation, setHomeLocation] = useState('/');

    useEffect(() => {
        if (locationState.city && locationState.city.toLowerCase() !== 'delhi') {
            setHomeLocation(`${locationState.city.toLowerCase()}-${locationState.state.toLowerCase()}`);
        } else {
            setHomeLocation(`/${locationState.city.toLowerCase()}`);
        }
    }, [locationState]);

    return <header className="site_header">
        <div className="container position-relative">
            <div className="header_row">
                <div className="left_elements">
                    <div className="site_logo" style={{width: "70px"}}>
                        <Link to={homeLocation}><img src="/assets/images/bb-logo-white.png" alt="" srcSet=""/></Link>
                    </div>
                    <div className="search_loaction ps-xl-4">
                        <div className="search_bar">
                            <a href="javascript:void(0)" onClick={() => setShowSearchPopup(true)}><i
                                className="fa-solid fa-magnifying-glass"></i><span className="ps-xl-2">Search</span></a>
                        </div>
                        <a className="change_location min_lenght_text" data-maxlength="11" data-bs-toggle="modal"
                           data-bs-target="#location_slide">
                            <i className="fa-solid fa-location-dot"></i><span
                            className="ps-sm-2 d-sm-inline-block d-none">{locationState.address.substring(0, 15)}...</span>
                        </a>
                    </div>
                </div>
                <div className="right_elements main-navigation">
                    <button className="menu-toggle d-block d-lg-none m-0">
                        <div className="humbergur__menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                    <div className="get_app d-md-inline-block d-none">
                        <span>
                            Get App:
                            <a href="https://apps.apple.com/in/app/sellonfly/id6471555505" target="_blank"><img src="/assets/images/apple.png" alt="" srcSet=""/></a>
                            <a href="https://play.google.com/store/apps/details?id=com.sellonfly" target="_blank"><img
                                src="/assets/images/android.png" alt="" srcSet="" /></a>
                        </span>
                    </div>
                    <ul className="mainmenu menu">
                        <li className=""><a onClick={() => setShowCategoryPopup(true)} style={{cursor: 'pointer'}}>Categories</a></li>
                        {/* <li className=""><Link to={'/vouchers'}  style={{cursor: 'pointer'}}>Vouchers</Link></li> */}
                        {/* <li>{
                            locationState.state && locationState.city ? <Link to={`/offer-deals`}>Offers & Deals</Link> : <Link to={`/offer-deals`}>Offers & Deals</Link>
                        }</li> */}
                        {/* <li><a href="javascript:void(0);">BohniDeals <img src="/assets/images/fire.gif" className="fire_gif" alt="" srcSet=""/></a></li> */}
                        {/*<li><Link to={`/gold-membership`}>Gold Membership</Link></li>*/}
                    </ul>
                    {/* <div className="login_profile">
                        {userState.token !== null ? <div className="my_account_block position-relative">
                            <a href="javascript:void(0);" className="my_account_icon min_lenght_text"
                               data-maxlength="8" title="Abhishek sharma">
                                <i className="fa-solid fa-user pe-sm-2"></i><span
                                className="d-sm-inline-block d-none">{userState.name}</span>
                            </a>
                            <div className="acc_drop">
                                <ul className="">
                                    <li className="active"><Link to={'/update-profile'}><i
                                        className="fa-solid fa-user pe-2"></i>Profile</Link></li>
                                    <li><Link to={'/orders'}><i className="fa-solid fa-bag-shopping pe-2"></i>Orders</Link>
                                    </li>
                                    <li><a href="refer-earn.html"><i
                                        className="fa-solid fa-hand-holding-dollar pe-2"></i>Refer & Earn</a></li>
                                    <li><a href="javascript:void(0);" onClick={() => {
                                        store.dispatch({
                                           type: logoutSuccess,
                                        });
                                    }}><i className="fa-solid fa-right-from-bracket pe-2"></i>Logout</a>
                                    </li>
                                </ul>
                            </div>
                        </div> : <a href="javascript:void(0);" onClick={() => setShowLoginPopup(true)} className="login_btn">
                            <i className="fa-solid fa-user d-lg-none pe-sm-2 pe-0"></i><span
                            className="d-sm-inline-block d-none">Login</span>
                        </a>}
                    </div> */}
                </div>
            </div>
        </div>
        <Login handleClose={() => setShowLoginPopup(!showLoginPopup)} show={showLoginPopup}/>
        <CategoryModel title={'All Categories'} handleClose={() => setShowCategoryPopup(false)} show={showCategoryPopup}/>
        <Search handleClose={() => setShowSearchPopup(false)} show={showSearchPopup}/>
    </header>;
}

export default Header;

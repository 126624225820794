import {Link} from "react-router-dom";

function Footer() {
    return <footer className="site_footer">
        <div className="container">
            <div className="footer_row row">
                <div className="col-lg-3 col-12 mb-lg-0 mb-4">
                    <div className="foot_logo"><img src="/assets/images/bb-logo-white.png" alt="" srcSet="" />
                    </div>
                    <div className="foot_des">
                        <p className="m-0">Striving towards making the world a better place to shop with great
                            savings!</p>
                    </div>
                    <ul className="social_icon">
                        <li><a href="https://www.facebook.com/bohnibattaofficial" target="_blank"><i
                            className="fa-brands fa-facebook-f"></i></a></li>
                        <li><a href="https://www.instagram.com/bohnibatta/" target="_blank"><i
                            className="fa-brands fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/@bohnibatta" target="_blank"><i
                            className="fa-brands fa-youtube"></i></a></li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-3 col-6 mb-md-0 mb-4 foot_links">
                    <h3>Quick Links</h3>
                    <ul className="">
                        <li><Link to={'/stores'}>All Stores</Link></li>
                        <li><Link to={'/about-us'}>About Us</Link></li>
                        <li><Link to={'/faq'}>FAQ's</Link></li>
                        <li><Link to={'/contact-us'}>Contact Us</Link></li>
                        <li><Link to={'/terms-of-use#privacy_policy'}>Privacy Policy</Link></li>
                        <li><Link to={'/terms-of-use'}>Terms of Use</Link></li>
                        <li><Link to={'/delete-account'}>Request Account Deletion</Link></li>

                    </ul>
                </div>
                <div className="col-lg-2 col-md-3 col-6 mb-md-0 mb-4 foot_links">
                    <h3>General</h3>
                    <ul>
                        {/*<li><Link to={'https://blog.bohnibatta.com/'} target={'_blank'}>Blogs</Link></li>*/}
                        <li><Link to={'/partner'}>Seller Registration/List Your Business</Link></li>
                        <li><Link to={'/how-it-works'}>How It Works</Link></li>
                        <li><Link to={'/seller-delete-account'}>Seller Request Account Deletion</Link></li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-3 col-6 foot_links">
                {/*<h3>More</h3>*/}
                {/*    <ul>*/}
                {/*        <li><a href="brands.html">Brand Offers</a></li>*/}
                {/*        <li><a href="bank-offers.html">Bank Offer</a></li>*/}
                {/*        <li><a href="festival-offers.html">Festival offer</a></li>*/}
                {/*    </ul>*/}
                </div>
                <div className="col-lg-3 col-md-3 col-6 download_app">
                    <h3>Download App</h3>
                    <div className="download_imgs">
                        <a href="https://play.google.com/store/apps/details?id=com.sellonfly" className="g_play_store"
                           target="_blank">
                            <img src="/assets/images/playstore.png" alt="" srcSet="" />
                        </a>
                        <a href="https://apps.apple.com/in/app/sellonfly/id6471555505" target="_blank" className="app_store"><img src="/assets/images/appstore.png" alt="" srcSet="" /></a>
                    </div>
                </div>
            </div>
            <div className="copywrite_text text-center">
                <p className="m-0">© 2023 SellOnFly <a href="https://sellonfly.com" target="_blank">Powered By
                    SellOnFly</a>.</p>
            </div>
        </div>
    </footer>;
}

export default Footer;

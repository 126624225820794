import { combineReducers } from '@reduxjs/toolkit'
import auth from "./auth/reducers";
import common from "./common/reducers";
import location from "./location/reducers";

const rootReducer = combineReducers({
    auth: auth,
    common: common,
    location: location,
})

export default rootReducer

import Api from "../../services/Api";
import BBEndpoints from "../../core/config/Endpoints";

export default class MembershipService {

    async isGoldMember(): Promise<any> {
        let token = localStorage.getItem("token") || '';
        if (token) {
            const res = await Api.get(BBEndpoints.isGoldMember, {
                "Content-Type": "application/json",
                "Authorization": token,
            });
            console.log(res);
            return res;
        }
        return false;
    }

    async cardDetails(): Promise<any> {
        let token = localStorage.getItem("token") || '';
        if (token) {
            return await Api.get(BBEndpoints.goldMember, {
                "Content-Type": "application/json",
                "Authorization": token,
            });
        }
        return false;
    }

    async createRazorpayOrder() {
        const token = localStorage.getItem("token") || '';
        let res = await Api.get(
            BBEndpoints.createGoldMemberOrder, {
                "Content-Type": "application/json",
                "Authorization": token,
            });
        return res['orderId'];
    }

    async verifyOrder(payment: any) {
        const token = localStorage.getItem("token") || '';
        return await Api.post(BBEndpoints.verifyGoldMemberOrder, payment, {
            "Content-Type": "application/json",
            "Authorization": token,
        });
    }
}
import {Modal} from "react-bootstrap";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import ListService from "../../services/ListService";
import {useSelector} from "react-redux";
import VoucherService from "./Service";
import {toast} from "react-hot-toast";

const SuperSaverVouchers = ({store, show, handleClose}: { store: any, show: boolean, handleClose: VoidFunction }) => {
    const commonState = useSelector((state: any) => state.common);
    const locationState = useSelector((state: any) => state.location);
    const [listService] = useState(new ListService());
    const [voucherService] = useState(new VoucherService());
    const [vouchers, setVouchers] = useState<any>([]);

    const getVouchers = async () => {
        if (commonState.locations.length > 0 && locationState.city && store) {
            const location = commonState.locations.find((loc: any) => loc.title == locationState.city);
            const items = await listService.vouchers(location._id, {limit: 50, coordinates: locationState.coordinates, superSaver: true, store: store['_id']});
            setVouchers(items);
        }
    };

    useEffect(() => {
        getVouchers();
    }, [commonState, locationState, store]);

    return (
        <>
            {store != null && <Modal className="modal fade" id="voucherdetail_modal" role="dialog"
                                     show={show} size="lg"
                                     aria-labelledby="voucherdetailLabel"
                                     centered>
                <Modal.Dialog className="modal-dialog modal-dialog-centered modal-xl" style={{width: '100%', height: '100%'}}>
                    <div className="modal-content modal_content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => handleClose()}></button>
                        <div className="visit_store_link mt-4 text-center"><Link to={`/store/${store['_id']}`} className=""><i
                            className="fa-solid fa-shop pe-2"></i>Visit Store</Link></div>
                        <div className="modal-body">
                            <div className="voucher_listing row">
                                {vouchers.map((voucher: any) => <div className="voucher_img col-xl-3 col-lg-4 col-md-6"><a href="#" className="d-block"><img
                                    src={voucher['image']} alt="" srcSet=""/><span
                                    className="hover_text" onClick={async () => {
                                    let res = await voucherService.claim(voucher['_id']);
                                    if (res) {
                                        toast.success('Voucher claimed successfully!');
                                    }
                                }}>Claim Now</span></a></div>)}
                            </div>
                        </div>
                    </div>
                </Modal.Dialog>
            </Modal>}
        </>
    );
}

export default SuperSaverVouchers;

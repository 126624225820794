import Api from '../../services/Api';
import BBEndpoints from '../../core/config/Endpoints';

export class AccountDeletionService {
  async sellerRequestAccountDeletion(mobile: string): Promise<string[]> {
    return await Api.get(BBEndpoints.sellerRequestAccountDeletion, {
      "Content-Type": "application/json",
    }, {
      mobile,
    });
  }
}